import {
    FormDataConsumer,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput,
    AutocompleteInput,
    NumberInput,
    maxValue,
    regex,
    minLength,
    maxLength
} from "react-admin";
import {withServerConstants} from "providers/ServerConstantsProvider";
import React from "react";
import SimpleFormCustom from "../SimpleFormCustom";
import {getChoicesList} from "../../utils/common";
import {ActionType} from "../../interfaces/CommonInterface";
import {minValue} from "ra-core/esm/form/validate";
import {EMAIL_REGEXP} from "../../constants/common";


const TrailForm = (props: any) => {
    return (
        <SimpleFormCustom
            isLoaded={props.actionType == ActionType.ADD || !!props.record}
            {...props}

        >
            <TextInput source="email" type="email"
                       validate={[regex(EMAIL_REGEXP, 'Email must be like exmaple+123@gmail.com')]}/>
            <TextInput source="name"/>
            <TextInput source="last_name"/>
            <TextInput source="password" validate={[
                minLength(8, 'Password length must be >= 8'),
                maxLength(32, 'Password length must be <= 32'),
            ]}
            />
            <TextInput source="phone"/>
            <ReferenceInput label="Country" source="country_id" reference="country"
                            sort={{}}
            >
                <AutocompleteInput optionText="name" resettable={true}/>
            </ReferenceInput>
            <FormDataConsumer>
                {({formData}) => (
                    <ReferenceInput label="Region" source="state_id" reference="state"
                                    sort={{}}
                                    filter={formData.country_id ? {country_id: formData.country_id} : {}}
                                    filterToQuery={searchText => (searchText ? {name: searchText} : {})}
                                    disabled={!formData.country_id}
                    >
                        <AutocompleteInput optionText="name" resettable={true}/>
                    </ReferenceInput>
                )
                }
            </FormDataConsumer>
            <SelectInput
                key="default_activity_type"
                label="Default Activity Type"
                source={'default_activity_type'}
                choices={getChoicesList(Object.values(props.serverConstants?.activity_type.items))}
            />
            <SelectInput
                key="default_map_type"
                label="Default Map Type"
                source={'default_map_type'}
                choices={getChoicesList(Object.values(props.serverConstants?.map_type.items))}
            />
            <SelectInput
                key="gender"
                label="Gender"
                source={'gender'}
                choices={getChoicesList(Object.values(props.serverConstants?.gender.items))}
            />
            <SelectInput
                key="units"
                label="Units"
                source={'units'}
                choices={getChoicesList(Object.values(props.serverConstants?.units.items))}
            />
            <NumberInput
                source="age"
                label="Age"
                min="16"
                max="99"
                step="1"
                validate={[
                    minValue(30, 'Value must be >= 16'),
                    maxValue(150, 'Value must be <= 99'),
                ]}
            />
            <NumberInput
                source="height"
                label="Height"
                min="40"
                max="250"
                step="1"
                validate={[
                    minValue(30, 'Value must be >= 40'),
                    maxValue(250, 'Value must be <= 250'),
                ]}
            />
            <NumberInput
                source="weight"
                label="Weight"
                min="30"
                max="150"
                step="1"
                validate={[
                    minValue(30, 'Value must be >= 30'),
                    maxValue(150, 'Value must be <= 150'),
                ]}
            />
            <BooleanInput source="is_paid"/>
            <BooleanInput source="is_registration_completed"/>
            <NumberInput
                source="sessions_count"
                min="0"
                step="1"
                validate={[
                    minValue(1, 'Value must be >= 0'),
                ]}
            />
            <BooleanInput source="is_nps_sent"/>
        </SimpleFormCustom>
    )
};

export default withServerConstants(TrailForm)
