import {SelectInput, SelectArrayInput, AutocompleteInput, FormDataConsumer, ReferenceInput} from "react-admin";
import React from "react";

export const renderFilterSelectInput = (
    fieldName: string,
    choices: object[],
    actionType?: string,
    optionText?: string,
) => <SelectInput
        key={fieldName}
        source={fieldName}
        choices={choices}
        allowEmpty={false}
        resettable
        alwaysOn
        optionText={optionText}
    />

export const renderFilterReferenceInput = (
    label: string,
    fieldName: string,
    reference: string,
    filter?: any,
    filterToQuery?: any,
    disabled?: any,
    optionText?: string,
) => <ReferenceInput
        label={label}
        source={fieldName}
        reference={reference}
        sort={{}}
        filter={filter}
        disabled={disabled}
        filterToQuery={filterToQuery}
        alwaysOn
        helperText={false}
>
        <AutocompleteInput
            optionText={optionText}
            resettable={true}
        />
</ReferenceInput>

export const renderFilterArraySelectInput = (
    fieldName: string,
    choices: object[],
    actionType?: string,
    optionText?: string,
) => <SelectArrayInput
    key={fieldName}
    source={fieldName}
    choices={choices}
    allowEmpty={false}
    resettable
    alwaysOn
    optionText={optionText}
/>
