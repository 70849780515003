import {cleanObject} from "../utils/common";

export default abstract class AbstractNormalizer {
    abstract create(payload: any): any;

    abstract update(payload: any): any;

    protected beforeUpdate(payload: any, is_clear: boolean = false): any {
        const data = is_clear ? cleanObject(payload) : payload;
        delete data.created_at;
        delete data.updated_at;
        delete data.created_by;
        delete data.last_updated_by;
        delete data.id;

        return data;
    }
}
