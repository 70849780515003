import {
    FormDataConsumer,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput,
    required,
    AutocompleteInput,
    CheckboxGroupInput,
    NumberInput
} from "react-admin";
import {withServerConstants} from "providers/ServerConstantsProvider";
import MapEditInstance from "../../components/Map/MapEditInstance";
import React from "react";
import SimpleFormCustom from "../SimpleFormCustom";
import {getChoicesList} from "../../utils/common";
import {ActionType} from "../../interfaces/CommonInterface";
import {EditableImage} from "../../components/Image/EditableImageField";
import {getCoordinates} from "../../utils/map";

const cityValidation = (value: any, allValues: any) => {
    if (!value && !allValues.park_id) {
        return 'City is required';
    }
    return undefined;
};
const parkValidation = (value: any, allValues: any) => {
    if (!value && !allValues.city_id) {
        return 'Park is required';
    }
    return undefined;
};

const validateTrailCreation = (values: any) => {
    const errors: any = {};
    if (!values.route_polyline) {
        errors.route_polyline = 'The route data is required';
    }
    if (!values.elevation_polyline) {
        errors.elevation_polyline = 'Elevation data is empty, please exit from edit mode';
    } else {
        const coods: any = getCoordinates(values.elevation_polyline)
        if(coods.some((item: any) => item[0] === 0)) {
            errors.elevation_polyline = 'Elevation data has 0 points, please be sure that trail fully in edit area';
        }
    }

    return errors
};

const TrailForm = (props: any) => (
    <SimpleFormCustom
        isLoaded={props.actionType == ActionType.ADD || !!props.record.route_polyline}
        validate={validateTrailCreation}
        {...props}
    >
        <TextInput source="name" validate={[required('Name is required')]}/>
        <TextInput
            multiline
            rows={5}
            source="moderator_notes"
            fullWidth
            validate={[required('Moderator notes is required')]}
        />
        <EditableImage source="origin_img_data" {...props} label="Main image" aspect={1.74}/>
        <EditableImage source="thumb_img_data" {...props} label="Preview Image"/>
        <ReferenceInput label="Country" source="country_id" reference="country"
                        sort={{}}
                        validate={[required('Country is required')]}
        >
            <AutocompleteInput optionText="name" resettable={true}/>
        </ReferenceInput>
        <FormDataConsumer>
            {({formData}) => (
                <ReferenceInput label="Region" source="state_id" reference="state"
                                sort={{}}
                                filter={formData.country_id ? {country_id: formData.country_id} : {}}
                                filterToQuery={searchText => (searchText ? {name: searchText} : {})}
                                disabled={!formData.country_id}
                                validate={[required('Region is required')]}
                >
                    <AutocompleteInput optionText="name" resettable={true}/>
                </ReferenceInput>
            )
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {({formData}) => (
                <ReferenceInput label="City" source="city_id" reference="city"
                                sort={{}}
                                filter={formData.state_id ? {state_id: formData.state_id} : {}}
                                filterToQuery={searchText => (searchText ? {name: searchText} : {})}
                                disabled={!formData.state_id}
                                validate={cityValidation}
                >
                    <AutocompleteInput optionText="name" resettable={true} loaded={true}/>
                </ReferenceInput>
            )}
        </FormDataConsumer>
        <FormDataConsumer>
            {({formData}) => (
                <ReferenceInput label="Park" source="park_id" reference="park"
                                sort={{}}
                                filter={formData.state_id ? {state_id: formData.state_id} : {}}
                                filterToQuery={searchText => (searchText ? {name: searchText} : {})}
                                disabled={!formData.state_id}
                                validate={parkValidation}
                >
                    <AutocompleteInput optionText="name" resettable={true} loaded={true}/>
                </ReferenceInput>
            )}
        </FormDataConsumer>
        <SelectInput
            key="route_type"
            label="Route Type"
            source={'route_type'}
            choices={getChoicesList(Object.values(props.serverConstants?.route_type.items))}
            validate={[required('Route Type is required')]}
        />
        <CheckboxGroupInput
            source="activities"
            choices={getChoicesList(Object.values(props.serverConstants?.activity_type.items))}
            validate={[required('Activity Type is required')]}
            resettable="true"
        />
        <NumberInput
            source="avg_rating"
            label="Rating(1-5)"
            min="1"
            max="5"
            step="0.1"
            validate={[required('Rating is required')]}
        />
        <BooleanInput source="is_closed" defaultValue={false}/>
        <BooleanInput source="is_private_property" defaultValue={false}/>
        <SelectInput
            key="status"
            source={'status'}
            choices={getChoicesList(Object.values(props.serverConstants?.trail_status.items))}
            validate={[required('Status is required')]}
        />
        <TextInput source="elevation_polyline" label="Elevation data" disabled/>
        <TextInput source="route_polyline" label="Route data" disabled/>
        <FormDataConsumer>
            {(props: any) => {
                return <MapEditInstance {...props}/>
            }}
        </FormDataConsumer>

    </SimpleFormCustom>
)

export default withServerConstants(TrailForm)
