import React, { memo } from 'react';
import {
  Edit,
} from 'react-admin';

import TrailForm from 'forms/Trail/TrailForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import TrailNormalizer from "../../normalizer/TrailNormalizer";

const TrailEdit = (props: ControllerPropsInterface) => {
  return (
    <Edit {...props}>
      <TrailForm
        actionType={ActionType.EDIT}
        onSuccess={defaultOnSuccess}
        redirect="list"
        transform={TrailNormalizer.update}
        {...props}
      />
    </Edit>
  )
};

export default memo(TrailEdit);
