import React from 'react';
import {
    ReferenceField,
    ShowButton,
    TextField,
} from 'react-admin';
import List from 'components/List';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, If, Otherwise, When} from 'typings/tsx-control-statements.d';
import { withServerConstants } from "../../providers/ServerConstantsProvider";
import ExternalTrailFilter from "../../filters/Trail/ExternalTrailFilter";
import {isAdmin} from "../../utils/authProviderProvider";

const rowStyle = (record: any) => ({
    borderLeftColor: '#333',
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
});


const ExternalTrail = (props: ControllerPropsInterface) => (
    <Choose>
        <When condition={props.permissions}>
            <If condition={isAdmin(props.permissions)}>
                <List
                    filters={<ExternalTrailFilter serverConstants={props.serverConstants} />}
                    filterDefaultValues={{ has_trails: true}}
                    rowStyle={(record) => rowStyle(record)}
                    {...props}
                >
                    <TextField source="id"/>
                    <ReferenceField source="country_id" reference="country">
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField source="state_id" reference="state">
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField source="city_id" reference="city">
                        <TextField source="name"/>
                    </ReferenceField>
                    <TextField source="name" label="Name"/>
                    <TextField source="popularity"/>
                    <TextField source="route_type"/>
                    <TextField source="trails_count" />
                    <ShowButton />
                </List>
            </If>
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
)


export default withServerConstants(ExternalTrail);
