import React from 'react';
import { Filter, TextInput } from 'react-admin';
import {renderFilterReferenceInput} from "../FiltersUtils";

const StateFilter = (props: any) => {
  const { serverConstants, ...rest } = props;

  return (
    <Filter {...rest}>
        {renderFilterReferenceInput('Country', 'country_id', 'country')}
        <TextInput source="id" alwaysOn resettable />
        <TextInput source="name" alwaysOn resettable />
    </Filter >
  );
};

export default StateFilter;
