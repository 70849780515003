import { ArrayField, Datagrid, TextField, useRecordContext } from 'react-admin';
import LinkToTrail from "../Button/LinkToTrail";

const TrailsShowList = (props: any) => {
    const record = useRecordContext(props);

    return record?.trails
        ?
        <ArrayField source="trails">
            <Datagrid>
                <TextField source="trail_id" label="Trail Id" emptyText={'N/A'}/>
                <LinkToTrail />
            </Datagrid>
        </ArrayField>
        :
        null;
}

export default TrailsShowList;
