import React from 'react';
import {Filter, FormDataConsumer, TextInput, NullableBooleanInput} from 'react-admin';
import {renderFilterReferenceInput, renderFilterSelectInput} from "../FiltersUtils";
import {getChoicesList} from "../../utils/common";

const ParkFilter = (props: any) => {
  const { serverConstants, ...rest } = props;

  return (
    <Filter {...rest}>
        {renderFilterReferenceInput('Country', 'country_id', 'country')}
        <FormDataConsumer form={'filterForm'} alwaysOn source='state_id'>
            {({ formData }) => renderFilterReferenceInput(
                    'Region',
                    'state_id',
                    'state',
                props.filterValues.country_id ? { country_id: props.filterValues.country_id } : {},
                (searchText: any) => (searchText ? {name: searchText} : {}),
                !formData.country_id
                )}
        </FormDataConsumer>
        {renderFilterSelectInput('type', getChoicesList(Object.values(serverConstants?.park_type?.items)))}
        <TextInput source="id" alwaysOn resettable />
        <TextInput source="name" alwaysOn resettable />
        <TextInput source="moderator_notes" alwaysOn resettable />
        <NullableBooleanInput source="is_covered" label="Is Covered" alwaysOn resettable />
    </Filter >
  );
};

export default ParkFilter;
