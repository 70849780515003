import ReactDOM from "react-dom";

export default class MapboxGLButtonControl {
    constructor({
                    id = "",
                    className = "",
                    title = "",
                    eventHandler = undefined,
                    icon = null,
                    disabled = false,
                }) {
        this._id = id;
        this._className = className;
        this._title = title;
        this._eventHandler = eventHandler;
        this._icon = icon;
        this._disabled = disabled;
    }

    onAdd(map) {
        this._map = map;
        this._btn = document.createElement("button");
        this._btn.disabled = this._disabled;
        if (this._icon) {
            ReactDOM.render(this._icon, this._btn)
        }
        this._btn.className = "mapboxgl-ctrl-icon" + " " + this._className;
        this._btn.type = "button";
        this._btn.title = this._title;
        this._btn.onclick = this._eventHandler;

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    getBtn() {
        return this._btn;
    }

    setDisabled(state = false) {
        this._btn.disabled = state;
    }

    getId() {
        return this._id
    };
}
