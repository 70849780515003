import React from 'react';
import {Filter, FormDataConsumer, TextInput} from 'react-admin';
import {renderFilterReferenceInput} from "../FiltersUtils";

const CityFilter = (props: any) => {
  const { serverConstants, ...rest } = props;

  return (
    <Filter {...rest}>
        {renderFilterReferenceInput('Country', 'country_id', 'country')}
        <FormDataConsumer form={'filterForm'} alwaysOn source='state_id'>
            {({ formData }) => renderFilterReferenceInput(
                    'Region',
                    'state_id',
                    'state',
                props.filterValues.country_id ? { country_id: props.filterValues.country_id } : {},
                (searchText: any) => (searchText ? {name: searchText} : {}),
                !formData.country_id
                )}
        </FormDataConsumer>
        <TextInput source="id" alwaysOn resettable />
        <TextInput source="name" alwaysOn resettable />
    </Filter >
  );
};

export default CityFilter;
