import React from 'react';
import {
    Show,
} from 'react-admin';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, Otherwise, When} from 'typings/tsx-control-statements.d';
import { withServerConstants } from "../../providers/ServerConstantsProvider";
import PointsMap from "../../components/Map/PointsMap";

const CountryShow = (props: ControllerPropsInterface) => (
    <Choose>
        <When condition={true}>
            <Show {...props}>
                <PointsMap />
            </Show>
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
)


export default withServerConstants(CountryShow);
