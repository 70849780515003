import React from 'react';
import {
    ReferenceField,
    ShowButton,
    TextField,
    NumberField,
    DateField,
    ImageField,
    FunctionField,
    CloneButton
} from 'react-admin';
import List from 'components/List';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, If, Otherwise, When} from 'typings/tsx-control-statements.d';
import {withServerConstants} from "../../providers/ServerConstantsProvider";
import UserActivityFilter from "../../filters/User/UserActivityFilter";
import { Record } from 'ra-core';
import {toHoursAndMinutes, toKm} from "../../utils/common";
import {isAdmin} from "../../utils/authProviderProvider";
import {getBboxFromCoordinates, getCoordinates} from "../../utils/map";

const rowStyle = (record: any) => ({
    borderLeftColor: '#333',
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
});

const User = (props: ControllerPropsInterface) => (
    <Choose>
        <When condition={props.permissions}>
            <If condition={isAdmin(props.permissions)}>
                <List
                    filters={<UserActivityFilter/>}
                    rowStyle={(record) => rowStyle(record)}
                    sort={{field: 'id',order: 'DESC'}}
                    {...props}
                >
                    <TextField source="id" />
                    <TextField source="name" emptyText={'N/A'}/>
                    <ImageField source="image_small" emptyText={'N/A'}/>
                    <ReferenceField source="user_id" reference="user" emptyText={'N/A'}><TextField source="id" /></ReferenceField>
                    <ReferenceField source="trail_id" reference="trail" emptyText={'N/A'}><TextField source="id" /></ReferenceField>
                    <TextField source="activity_type" />
                    <FunctionField
                        source="total_time"
                        render={(record: Record | undefined) => toHoursAndMinutes(record?.total_time)}
                    />
                    <FunctionField
                        source="distance"
                        render={(record: Record | undefined) => toKm(record?.distance) + 'km'}
                    />
                    <FunctionField
                        source="avg_speed"
                        emptyText={'0'}
                        render={(record: Record | undefined) => `${Math.round(record?.avg_speed/1000*3600)}km/h`}
                    />
                    <FunctionField
                        source="avg_pace"
                        emptyText={'0'}
                        render={(record: Record | undefined) => toHoursAndMinutes(record?.avg_pace*1000)}
                    />
                    <NumberField source="calories_burned" emptyText={'0'}/>
                    <NumberField source="elevation_gain" emptyText={'0'}/>
                    <NumberField source="elevation_loss" emptyText={'0'}/>
                    <DateField source="created_at" showTime/>
                    <DateField source="updated_at" showTime/>
                    <ShowButton/>
                    <FunctionField
                        render={(record: Record | undefined) => {
                            if (record !== undefined) {
                                const coordinates = getCoordinates(record.route_polyline);
                                return (<CloneButton basePath="/trail" record={{
                                    id: record?.id || 1,
                                    status: 'draft',
                                    source: 'ugc',
                                    activities: [record.activity_type],
                                    route_polyline: record.route_polyline,
                                    elevation_polyline: record.elevation_polyline,
                                    elevation_gain: record.elevation_gain,
                                    length: record.distance,
                                    duration: record.total_time,
                                    longitude: coordinates[0][0],
                                    latitude: coordinates[0][1],
                                    bbox: getBboxFromCoordinates([coordinates]),
                                }} label="Create Trail"/>
                                )
                            }
                        }}
                    />
                </List>
            </If>
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
)


export default withServerConstants(User);
