import * as React from "react";
import {Chip} from '@material-ui/core';

const STATUS_COLORS: any = {
    draw: "#d9ead3",
    osm: "#d3daea",
    ugc: "#e2d9a2",
    external: "#e6b8af",
    imported: "#9edef1",
}

export const SourceChip = (props: any) => {
    const source = props.record[props.source]
    return (
        <Chip
            label={props.serverConstants?.trail_source?.items[source]?.title}
            style={{backgroundColor: STATUS_COLORS[source], color: '#000'}}
        />
    )
}
