import React from 'react';
import { Filter, TextInput } from 'react-admin';

const UserActivityFilter = (props: any) => {
  return (
    <Filter {...props}>
        <TextInput source="id" alwaysOn resettable />
        <TextInput source="user_id" alwaysOn resettable />
    </Filter >
  );
};

export default UserActivityFilter;
