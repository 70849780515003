import React, {useRef, useState} from "react";
import './Map.scss';
import Map, {
    FullscreenControl,
    MapRef,
    NavigationControl,
    ScaleControl,
    Source,
} from 'react-map-gl';
import LoopIcon from "@material-ui/icons/Loop";
import UndoIcon from "@material-ui/icons/Undo";
import AddLocation from "@material-ui/icons/AddLocation";
import {LngLat, LngLatBoundsLike} from 'mapbox-gl';
import MapboxGLButtonControl from './MapboxGLButtonControl'
import GeocoderControl from './GeocoderControl';
import {
    addLine,
    addLineSource,
    calculateElevationGain,
    clearSourceByName,
    createMarker,
    getBboxFromCoordinates,
    getBestRouteFromDirections,
    getCoordinates,
    getCoordinatesFromPolyline,
    getPlace,
    getPolylineFromCoordinates,
    getRouteDuration,
    isStartFinish,
    removeMarkersFromPoints
} from "../../utils/map";
import {LINE_COLOR} from "../../constants/map";
import {toHoursAndMinutes, toKm} from "../../utils/common";
import config from "../../config";
import {useForm} from "react-final-form";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import EditIcon from '@material-ui/icons/Edit';

const addRoute = async (tmpPoints: any, map: any, form: any, prev_data: any | null = null) => {
    const data = await getBestRouteFromDirections(tmpPoints);
    const coordinates = prev_data?.coordinates.length > 0 ? prev_data.coordinates.concat(data.geometry.coordinates) : data.geometry.coordinates;
    const distance = prev_data ? prev_data.length + data.distance : data.distance;
    const duration = prev_data ? prev_data.duration + data.duration : data.duration;
    form.change("route_polyline", getPolylineFromCoordinates(coordinates));
    form.change("longitude", coordinates[0][0]);
    form.change("latitude", coordinates[0][1]);
    form.change("length", distance);
    form.change("duration", duration);
    form.change("bbox", getBboxFromCoordinates([coordinates]));

    if (map.getSource('tmp-route')) {
        map.getSource('tmp-route').setData({
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: coordinates
            }
        });
    }
    return {
        coordinates: coordinates,
        length: distance,
        duration: duration
    }
}

const appendMarker = (tmpPoints: any, lngLat: any, map: any) => {
    const points: any = tmpPoints;
    let className = 'Map__marker__finish';

    if (points.length === 0) {
        className = 'Map__marker__start';
    } else if (points.length > 1) {
        points[points.length - 1].marker.remove();
        points[points.length - 1].marker = createMarker(points[points.length - 1].lngLat, map, 'Map__marker__edit')
    }

    points.push(
        {
            lngLat: lngLat,
            marker: createMarker(lngLat, map, className)
        }
    )
}

const prepareEditData = (coordinates: number[][], nearby_trails: any[], source: string, status: string, map: any) => {
    const startPoint = coordinates[0];
    const startLngLat = new LngLat(startPoint[0], startPoint[1]);
    const finishPoint = coordinates[coordinates.length - 1];
    const finishLngLat = new LngLat(finishPoint[0], finishPoint[1]);
    // getPlace(startPoint[0], startPoint[1]).then((data) => console.log(data));

    // Existing-data
    addLineSource('current-route-shadow', coordinates, map)
    addLine('current-route-shadow', LINE_COLOR, map, 0.6)

    if (isStartFinish(startPoint, finishPoint)) {
        createMarker(startPoint, map, 'Map__marker__start_finish__secondary')
    } else {
        createMarker(startPoint, map, 'Map__marker__start__secondary')
        createMarker(finishPoint, map, 'Map__marker__finish__secondary')
    }

    // Force erase of external draft trail
    if (source === 'external' && status === 'draft') {
        return []
    }

    addLineSource('current-route', coordinates, map)
    addLine('current-route', LINE_COLOR, map)

    return [
        {
            lngLat: startLngLat,
            marker: createMarker(startLngLat, map, 'Map__marker__start')
        },
        {
            lngLat: finishLngLat,
            marker: createMarker(finishLngLat, map, 'Map__marker__finish')
        }
    ]
}


const MapEditInstance = ({formData, record}: any) => {
    const mapRef = useRef<MapRef>(null);
    let isNearbyTrailsInit = false;
    let nearbyTrailsMarkers: any = [];
    const form = useForm();
    let bbox: LngLatBoundsLike | undefined = record?.bbox
    const existing_route_coordinates: any = getCoordinatesFromPolyline(
        record.route_polyline, record.route_polylines
    )
    let [isEditMode, setIsEditMode] = useState<boolean>(false);
    let currentCoordinates = existing_route_coordinates ? existing_route_coordinates[0] : null;
    let [tmpData, setTmpData] = useState<any>({
        duration: formData["duration"] || record.duration || 0,
        length: formData["length"] || record.length || 0,
        coordinates: formData["route_polyline"] ? getCoordinates(formData["route_polyline"]) : []
    })
    let [tmpPoints, setPoints] = useState<any>([]);
    const initViewState = bbox ? {bounds: bbox} : {
        longitude: -111.89110455,
        latitude: 41.0909022,
        zoom: 14,
    }

    const editMode = (event: any, map: any) => {
        if (map.getTerrain() != null) {
            map._controls.forEach((value: any) => {
                if (typeof value.getId !== "undefined" && value.getId() != 'edit_mode') {
                    value.setDisabled(false)
                }
            })
            map.setTerrain();
            setIsEditMode(true);
            map.getCanvas().style.cursor = 'crosshair'
        } else {
            map._controls.forEach((value: any) => {
                if (typeof value.getId !== "undefined" && value.getId() != 'edit_mode') {
                    value.setDisabled(true)
                }
            })
            map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
            setIsEditMode(false);
            map.getCanvas().style.cursor = 'grab'
        }
    }

    const clearTmpData = (map: any) => {
        setPoints((tmpPoints: any) => {
            removeMarkersFromPoints(tmpPoints)
            clearSourceByName(map, 'tmp-route');
            clearSourceByName(map, 'current-route');

            form.change("route_polyline", "");
            form.change("duration", 0);
            form.change("length", 0);
            form.change("bbox", 0);
            form.change("longitude", null);
            form.change("latitude", null);

            setTmpData( {
                coordinates: [],
                length: 0,
                duration: 0
            })
            return []
        })
    }

    const reverseData = async (event: any, map: any) => {
        setPoints((currentPoints: any) => {
            if (currentPoints.length > 1) {
                const newPoints = [...currentPoints].reverse();

                removeMarkersFromPoints(currentPoints)
                clearSourceByName(map, 'tmp-route');
                clearSourceByName(map, 'current-route');

                addRoute(newPoints, map, form).then((data) => {
                    setTmpData( {
                        coordinates: data.coordinates || 0,
                        length: data.length || 0,
                        duration: data.duration || 0
                    })
                });
                newPoints.forEach((point: any, index: any) => {
                    let className = 'Map__marker__edit';
                    if (index === 0) {
                        className = 'Map__marker__start';
                    } else if (index === newPoints.length - 1) {
                        className = 'Map__marker__finish';
                    }

                    point.marker = createMarker(point.lngLat, map, className)
                });

                return newPoints
            }

            return currentPoints
        })
    }

    const undo = async (event: any, map: any) => {
        setPoints((currentPoints: any) => {
            if (currentPoints.length > 2) {
                currentPoints[currentPoints.length - 1].marker.remove()
                currentPoints[currentPoints.length - 2].marker.getElement().className = 'Map__marker__finish'

                let newPoints = [...currentPoints]
                newPoints.splice(-1)

                addRoute(newPoints, map, form).then((data) => {
                    setTmpData( {
                        coordinates: data.coordinates,
                        length: data.length || 0,
                        duration: data.duration || 0
                    })
                });

                return newPoints
            }

            return currentPoints
        })
    }

    const showNearbyTrails = async (event: any, map: any) => {
        if (record?.nearby_trails?.length > 0) {
            if (isNearbyTrailsInit) {
                record.nearby_trails.forEach((nearby_trail: any) => {
                    const layerId = 'nearby-trail-' + nearby_trail.id + '-layer'
                    const visibility = map.getLayoutProperty(layerId, 'visibility');
                    // Toggle layer visibility by changing the layout object's visibility property.
                    if (visibility === 'visible' || visibility === undefined) {
                        map.setLayoutProperty(layerId, 'visibility', 'none');
                    } else {
                        map.setLayoutProperty(
                            layerId,
                            'visibility',
                            'visible'
                        );
                    }
                });
            } else {
                record.nearby_trails.forEach((nearby_trail: any) => {
                    let nearby_coordinates: any = getCoordinates(nearby_trail.route_polyline)
                    addLineSource('nearby-trail-' + nearby_trail.id, nearby_coordinates, map)
                    addLine('nearby-trail-' + nearby_trail.id, '#ff2a60', map, 0.4)
                    const start = nearby_coordinates[0];
                    const finish = nearby_coordinates[nearby_coordinates.length - 1];
                    if (isStartFinish(start, finish)) {
                        nearbyTrailsMarkers.push(createMarker(start, map, 'Map__marker__start_finish__secondary'))
                    } else {
                        nearbyTrailsMarkers.push(createMarker(start, map, 'Map__marker__start__secondary'))
                        nearbyTrailsMarkers.push(createMarker(finish, map, 'Map__marker__finish__secondary'))
                    }
                })
                isNearbyTrailsInit = true
            }
        } else {
            alert('No nearby trails for this trail');
        }
    }

    const outAndBack = async (event: any, map: any) => {
        setPoints((currentPoints: any) => {
            if (currentPoints.length >= 2 && currentPoints[0].lngLat !== currentPoints[currentPoints.length - 1].lngLat) {
                setTmpData((tmpData: any) => {
                    const newTmpData = {
                        coordinates: tmpData.coordinates.concat([...tmpData.coordinates].reverse()),
                        length: tmpData.length * 2,
                        duration: tmpData.length * 2
                    }

                    if (map.getSource('tmp-route')) {
                        clearSourceByName(map, 'tmp-route');
                        clearSourceByName(map, 'current-route');
                        map.getSource('tmp-route').setData({
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'LineString',
                                coordinates: newTmpData.coordinates
                            }
                        });
                    }

                    form.change("route_polyline", getPolylineFromCoordinates(newTmpData.coordinates));
                    form.change("length", newTmpData.length);
                    form.change("duration", newTmpData.duration);

                    const lngLat = currentPoints[0].lngLat;
                    appendMarker(currentPoints, lngLat, map)

                    return newTmpData
                })
            }

            return currentPoints
        });
    };

    const closeLoop = async (event: any, map: any) => {
        setPoints((currentPoints: any) => {
            if (currentPoints.length >= 2 && currentPoints[0].lngLat !== currentPoints[currentPoints.length - 1].lngLat) {
                const lngLat = currentPoints[0].lngLat;
                appendMarker(currentPoints, lngLat, map)
                addRoute(currentPoints, map, form).then((data) => {
                    setTmpData({
                        coordinates: data.coordinates || 0,
                        length: data.length || 0,
                        duration: data.duration || 0
                    })
                });
                form.change('route_type', 'loop')
            }

            return currentPoints
        })
    }

    const onLoad = React.useCallback((e: any) => {
        const map = e.target
        // Edit data
        if (currentCoordinates) {
            setPoints(prepareEditData(
                currentCoordinates,
                record.nearby_trails,
                record.source,
                record.status,
                map
            ))

            if (record.source === 'external' && record.status === 'draft') {
                clearTmpData(map)
            }
        }

        // Tmp data
        addLineSource('tmp-route', [], map)
        addLine('tmp-route', LINE_COLOR, map)
        map.addControl(new MapboxGLButtonControl({
            id: "edit_mode",
            className: "map-control",
            title: "Edit Mode",
            icon: <EditIcon/>,
            eventHandler: (event: any) => editMode(event, map)
        }), "top-left");

        // Controls
        map.addControl(new MapboxGLButtonControl({
            id: "clear",
            className: "map-control trash",
            title: "Clear",
            eventHandler: () => clearTmpData(map),
            disabled: true
        }), "top-left");

        map.addControl(new MapboxGLButtonControl({
            id: "undo",
            className: "map-control",
            title: "Undo",
            icon: <UndoIcon/>,
            eventHandler: (event: any) => undo(event, map),
            disabled: true
        }), "top-left");

        map.addControl(new MapboxGLButtonControl({
            id: "nearby_trails",
            className: "map-control",
            title: "Nerby trails",
            icon: <AddLocation/>,
            eventHandler: (event: any) => showNearbyTrails(event, map),
            disabled: true
        }), "top-left");

        map.addControl(new MapboxGLButtonControl({
            id: "reverse",
            className: "map-control",
            title: "Reverse",
            icon: <LoopIcon/>,
            eventHandler: (event: any) => reverseData(event, map),
            disabled: true
        }), "top-left");

        map.addControl(new MapboxGLButtonControl({
            id: "close_loop",
            className: "map-control",
            title: "Close loop",
            icon: <AllInclusiveIcon/>,
            eventHandler: (event: any) => closeLoop(event, map),
            disabled: true
        }), "top-left");

        map.addControl(new MapboxGLButtonControl({
            id: "out_and_back",
            className: "map-control",
            title: "Out and back",
            icon: <SwapHorizIcon/>,
            eventHandler: (event: any) => outAndBack(event, map),
            disabled: true
        }), "top-left");

        if (bbox) {
            map.setZoom(map.getZoom() - 0.3)
        }

        // Load pattern
        map.loadImage(
            '/pattern.png',
            (err: any, image: any) => {
                if (err) throw err;
                map.addImage('pattern', image);

                if (map.getSource('current-route')) {
                    map.addLayer({
                        'id': 'pattern-layer-current',
                        'type': 'line',
                        'source': 'current-route',
                        'paint': {
                            'line-pattern': 'pattern',
                            'line-width': 5
                        }
                    });
                }

                if (map.getSource('tmp-route')) {
                    map.addLayer({
                        'id': 'pattern-layer-tmp',
                        'type': 'line',
                        'source': 'tmp-route',
                        'paint': {
                            'line-pattern': 'pattern',
                            'line-width': 5
                        }
                    });
                }
            }
        );
        map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
    }, [tmpPoints])

    const onIdle = (e: any) => {
        if (tmpData.coordinates.length > 0) {
            const elevation_data = tmpData.coordinates.map((point: any, index: number) => [
                Math.round(mapRef?.current?.queryTerrainElevation(point, {exaggerated: false}) || 0),
                index / 1000
            ]);
            const elevationGain = calculateElevationGain(elevation_data)
            if (record.length && !record.duration) {
                const duration = getRouteDuration(record.length, elevationGain)
                setTmpData((tmpData: any) => ({
                    duration: duration,
                    length: tmpData.length,
                    coordinates: tmpData.coordinates
                }))
                form.change("duration", duration);
            }

            if (!isEditMode) {
                form.change("elevation_polyline", getPolylineFromCoordinates(elevation_data));
            } else {
                form.change("elevation_polyline", null);
            }
        }
    }

    const onClick = React.useCallback((e: any) => {
        if (isEditMode) {
            // if (e.originalEvent.target === e.target.getCanvas()) {
            if (tmpPoints.length >= 25) {
                alert("Unable to add more points. Max - 25!")
            } else {
                const map = e.target;
                const lngLat = e.lngLat;
                appendMarker(tmpPoints, lngLat, map)

                if (tmpPoints.length > 1) {
                    addRoute(tmpPoints, map, form).then((data) => {
                        setTmpData({
                            duration: data.duration || 0,
                            length: data.length || 0,
                            coordinates: data.coordinates || []
                        })
                    });
                }
            }
        }
        // }
    }, [tmpPoints, isEditMode])

    return (
        <Map
            id="EditMap"
            initialViewState={initViewState}
            style={{width: "100%", height: 800}}
            mapStyle="mapbox://styles/mapbox/outdoors-v9"
            onClick={onClick}
            onLoad={onLoad}
            onIdle={onIdle}
            ref={mapRef}
        >
            <Source id="mapbox-dem" type="raster-dem" url="mapbox://mapbox.terrain-rgb"/>
            <NavigationControl/>
            <FullscreenControl/>
            <ScaleControl/>
            <GeocoderControl
                mapboxAccessToken={config.MAPBOX_TOKEN}
                position="top-left"
            />
            <div className="sidebar">
                Length: {toKm(tmpData.length) || 0} km |
                Duration: {toHoursAndMinutes(tmpData.duration ||0)}
            </div>
        </Map>
    )
};

export default MapEditInstance;
