import React from 'react';
import {Filter, FormDataConsumer, SelectInput, TextInput} from 'react-admin';
import {renderFilterReferenceInput} from "../FiltersUtils";

const HAS_TRAILS_CHOICES = [
    {
        id: true,
        name: 'Yes'
    },
    {
        id: false,
        name: 'No'
    }
]

const TrailFilter = (props: any) => {
  const { serverConstants, ...rest } = props;

  return (
    <Filter {...rest}>
      {renderFilterReferenceInput('Country', 'country_id', 'country')}
        <FormDataConsumer form={'filterForm'} alwaysOn source='state_id'>
            {({ formData }) => renderFilterReferenceInput(
                'Region',
                'state_id',
                'state',
                props.filterValues.country_id ? { country_id: props.filterValues.country_id } : {},
                (searchText: any) => (searchText ? {name: searchText} : {}),
                !formData.country_id
            )}
        </FormDataConsumer>
        <FormDataConsumer form={'filterForm'} alwaysOn source='city_id'>
            {({ formData }) => renderFilterReferenceInput(
                'City',
                'city_id',
                'city',
                props.filterValues.state_id ? { state_id: props.filterValues.state_id } : {},
                (searchText: any) => (searchText ? {name: searchText} : {}),
                !formData.state_id
            )}
        </FormDataConsumer>
      <TextInput source="id" alwaysOn resettable />
      <TextInput source="name" alwaysOn resettable />
      <SelectInput source="has_trails" allowEmpty
         choices={HAS_TRAILS_CHOICES} label="Has Intersections"
         alwaysOn resettable/>
    </Filter >
  );
};

export default TrailFilter;
