import React, { memo } from 'react';
import {
  Edit,
} from 'react-admin';

import UserForm from 'forms/User/UserForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import UserNormalizer from "../../normalizer/UserNormalizer";

const UserEdit = (props: ControllerPropsInterface) => {
  return (
    <Edit {...props}>
      <UserForm
        actionType={ActionType.EDIT}
        onSuccess={defaultOnSuccess}
        redirect="list"
        transform={UserNormalizer.update}
        {...props}
      />
    </Edit>
  )
};

export default memo(UserEdit);
