import mapboxgl, {LngLatBoundsLike} from "mapbox-gl";
import {DEFAULT_LINE_WIDTH} from "../constants/map";
import config from "../config";

let polyline = require('@mapbox/polyline');


export const getCoordinatesFromPolyline = (polylineString: string | undefined, route_polylines: string[] | undefined) => {
    if (polylineString) {
        return [polyline.decode(polylineString)];
    }

    if (route_polylines) {
        let route_polylines_decoded: string[] = [];
        route_polylines.map((line: string) => {
            route_polylines_decoded.push(polyline.decode(line));
        });
        return route_polylines_decoded;
    }

    return undefined
};

export const getCoordinates = (polylineString: string) => {
    return polyline.decode(polylineString)
};

export const getPolylineFromCoordinates = (coordinates: any) => {
    return polyline.encode(coordinates)
};

export const getGeoJsonFromPolyline = (route_polyline: any) => {
    return polyline.toGeoJSON(route_polyline)
};

export const getBboxFromCoordinates = (coordinates: number[][][] | undefined) => {
    if (coordinates === undefined) {
        return undefined
    }

    const lonList: number[] = [];
    const latList: number[] = [];
    coordinates.map((arr: number[][]) => arr.map((v: number[]) => lonList.push(v[0])));
    coordinates.map((arr: number[][]) => arr.map((v: number[]) => latList.push(v[1])));

    const bbox: LngLatBoundsLike = [
        Math.max(...lonList), Math.max(...latList),
        Math.min(...lonList), Math.min(...latList)
    ]

    return bbox
};

export const getBboxFromRecord = (record: any) => {
    if (record.longitude_top_left) {
        const bbox: LngLatBoundsLike = [
            [record.longitude_bottom_right, record.latitude_bottom_right],
            [record.longitude_top_left, record.latitude_top_left]
        ]

        return bbox
    }

    return undefined
}


export const clearSourceByName = (mapInstance: any, sourceName: string) => {
    if (mapInstance.getSource(sourceName)) {
        mapInstance.getSource(sourceName).setData({
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: []
            }
        });
    }
}


export async function getBestRouteFromDirections(coordinates: any) {
    const tmp_coordinates = Object.keys(coordinates).reduce(function (result, current) {
        if (coordinates[current]) {
            const separator = result.length > 0 ? ';' : '';
            result += `${separator}${coordinates[current].lngLat.lng}, ${coordinates[current].lngLat.lat}`
        }

        return result
    }, '')

    const query = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/walking/${tmp_coordinates}?steps=true&overview=full&geometries=geojson&access_token=${config.MAPBOX_TOKEN}`,
        {method: 'GET'}
    );

    const json = await query.json();
    return json.routes[0];
}

export async function getPlace(long: number, lat: number) {
    const query = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?limit=1&access_token=${config.MAPBOX_TOKEN}`,
        {method: 'GET'}
    );

    const json = await query.json();
    return json.features[0];
}

export const removeMarkersFromPoints = (points: []) => {
    points.forEach((point: any) => {
        point.marker.remove();
    })
}


export const addLine = (sourceName: string, lineColor: string, map: any, opacity: number = 1) => {
    map.addLayer({
        id: sourceName + '-layer',
        type: 'line',
        source: sourceName,
        layout: {
            'line-join': 'round',
            'line-cap': 'round'
        },
        paint: {
            'line-color': lineColor,
            'line-width': DEFAULT_LINE_WIDTH,
            'line-opacity': opacity
        }
    });
}

export const createMarker = (lngLat: any, map: any, className: string = '', onClick: any = {}) => {
    const element = document.createElement('div');
    element.className = className;

    const marker = new mapboxgl.Marker({
        element: element,
        draggable: true
    })
        .setLngLat(lngLat)
        .addTo(map);

    element.addEventListener('click', onClick);

    return marker
}

export const addLineSource = (id: string, coordinates: any, map: any) => {
    map.addSource(id, {
        'type': 'geojson',
        'data': {
            'type': 'Feature',
            'properties': {},
            'geometry': {
                'type': 'LineString',
                'coordinates': coordinates,
            }
        }
    });
}

export const isStartFinish = (start: number[], finish: number[]) => start[0] === finish[0] && start[1] === finish[1]

export const getRouteDuration = (mDistance: number, mAscent: number, mDescent: number = 0): number => {
    let hDistance: number = (mDistance / 1000) / 4;
    let hUpAndDown: number = mAscent / 300 + mDescent / 500;
    let netDuration: number = Math.min(hDistance, hUpAndDown) / 2 + Math.max(hDistance, hUpAndDown);

    return (netDuration * 1.2) * 3600; // +20%
}

export const calculateElevationGain = (elevationCoordinates: [number, number][]): number => {
    let totalGain: number = 0;

    for (let i: number = 0; i < elevationCoordinates.length; i++) {
        let currentGain: number = i > 0 ? elevationCoordinates[i][0] - elevationCoordinates[i - 1][0] : 0;
        totalGain += currentGain > 0 ? currentGain : 0;
    }

    return totalGain;
}
