import { FormDataConsumer, RadioButtonGroupInput, SimpleForm, useRecordContext } from 'react-admin';
import TrailMap from './TrailMap';

const ExternalTrailMap = (props: any) => {
    const record = useRecordContext(props);

    return record?.trails?.length > 0
        ?
        <SimpleForm>
            <FormDataConsumer>
                {(record) => (
                    <>
                        <RadioButtonGroupInput
                            initialValue={record?.formData?.trails?.length > 0 ? record?.formData?.trails[0].id : undefined}
                            source="selected_trail_id"
                            optionText={((record: any) => `${record.name}(id: ${record.id}`)}
                            choices={record?.formData?.trails}
                        />
                        <TrailMap {...record} />
                    </>
                )
                }
            </FormDataConsumer>
        </SimpleForm >
        :
        <TrailMap {...props} />
}

export default ExternalTrailMap;
