import React from "react";
import './Map.scss';
import mapboxgl, { LngLatBoundsLike, MapboxGeoJSONFeature, MapMouseEvent } from 'mapbox-gl';
import MapInstance from "./MapInstance";
import { getBboxFromRecord, getCoordinates } from "../../utils/map";
import { Choose, Otherwise, When } from "../../typings/tsx-control-statements.d";
import Loader from "../Loader";

const getPoints = (record: any) => {
    const points: any[] = [];

    if (record.trails) {
        record.trails.forEach((v: any) => {
            points.push({
                'type': 'Feature',
                'properties': {
                    'name': v.name,
                    'id': v.id,
                    'color': '#f37373',
                    'link': `#/trail/${v.id}/show/2`
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': [v.longitude, v.latitude]
                }
            })
        })
    }
    if (record.external_trails) {
        record.external_trails.forEach((v: any) => {
            points.push({
                'type': 'Feature',
                'properties': {
                    'name': v.name,
                    'id': v.id,
                    'color': '#73f39c',
                    'link': `#/external-trail/${v.id}/show/2`
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': [v.longitude, v.latitude]
                }
            })
        })
    }

    return points
}

const PointsMap = (props: any) => {
    const bbox: LngLatBoundsLike | undefined = getBboxFromRecord(props.record)
    const area_border_coordinate = !!props.record.area_polygon_polyline ? getCoordinates(props.record.area_polygon_polyline) : []
    const sources: any[] = [
        {
            id: 'places',
            data: {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': getPoints(props.record)
                },
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50
            }
        },
        {
            id: 'area-border-coordinate',
            data: {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'MultiLineString',
                        'coordinates': [area_border_coordinate]
                    }
                }
            }
        }
    ];
    const layers: any[] = [
        {
            'id': 'outline',
            'type': 'line',
            'source': 'area-border-coordinate',
            'layout': {},
            'paint': {
                'line-color': '#002662',
                'line-width': 2
            }
        },
        {
            id: 'clusters',
            type: 'circle',
            source: 'places',
            filter: ['has', 'point_count'],
            paint: {

                'circle-color': '#cfc4ff',
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    18,
                    100,
                    26,
                    750,
                    35
                ],
                'circle-stroke-width': 2,
                'circle-stroke-color': '#ffffff'
            }
        },
        {
            id: 'cluster-count',
            type: 'symbol',
            source: 'places',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 18
            }
        },
        {
            id: 'unclustered-point',
            type: 'circle',
            source: 'places',
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': ['get', 'color'],
                'circle-radius': 8,
                'circle-stroke-width': 4,
                'circle-stroke-color': '#fff'
            }
        }
    ];
    const events: any[] = [
        {
            type: 'mouseenter',
            layers: ['clusters', 'unclustered-point'],
            handler: (e: MapMouseEvent) => {
                e.target.getCanvas().style.cursor = 'pointer';
            }
        },
        {
            type: 'mouseleave',
            layers: ['clusters', 'unclustered-point'],
            handler: (e: MapMouseEvent) => {
                e.target.getCanvas().style.cursor = '';
            }
        },
        {
            type: 'click',
            layers: 'clusters',
            handler: (e: MapMouseEvent) => {
                const features: MapboxGeoJSONFeature[] = e.target.queryRenderedFeatures(e.point, {
                    layers: ['clusters']
                });

                const clusterId = features[0]?.properties?.cluster_id;
                //@ts-ignore
                e.target.getSource('places').getClusterExpansionZoom(
                    clusterId,
                    (err: any, zoom: any) => {
                        if (err) return;
                        e.target.easeTo({
                            //@ts-ignore
                            center: features[0].geometry.coordinates,
                            zoom: zoom
                        });
                    }
                );
            }
        },
        {
            type: 'click',
            layers: 'unclustered-point',
            handler: (e: MapMouseEvent) => {
                //@ts-ignore
                const coordinates = e.features[0].geometry.coordinates.slice();
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                //@ts-ignore
                const name = e.features[0].properties.name
                //@ts-ignore
                const link = e.features[0].properties.link
                new mapboxgl.Popup()
                    .setLngLat(coordinates)
                    .setHTML(
                        `<div>
                            <a href="${link}" style="color: #000" target='_blank' >${name}</a>
                        </div>`
                    )
                    .addTo(e.target);
            }
        }
    ];

    return <Choose>
        <When condition={!!props.record.longitude_top_left}>
            <MapInstance
                bbox={bbox}
                longitude={props.record.longitude}
                latitude={props.record.latitude}
                sources={sources}
                layers={layers}
                events={events}
                markers={[]}
            />
        </When>
        <Otherwise>
            <Loader />
        </Otherwise>
    </Choose>
};

export default PointsMap;
