import React from 'react';
import {
    ShowButton,
    TextField,
    EditButton,
    ReferenceField,
    NumberField,
} from 'react-admin';
import List from 'components/List';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, Otherwise, When} from 'typings/tsx-control-statements.d';
import {withServerConstants} from "../../providers/ServerConstantsProvider";
import StateFilter from "../../filters/Trail/StateFilter";

const rowStyle = (record: any) => ({
    borderLeftColor: '#333',
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
});


const Region = (props: ControllerPropsInterface) => (
    <Choose>
        <When condition={!!props?.serverConstants?.countriesList}>
            <List
                filters={<StateFilter/>}
                filterDefaultValues={{ country_id: 205 }}
                rowStyle={(record) => rowStyle(record)}
                {...props}
            >
                <TextField source="name"/>
                <ReferenceField source="country_id" reference="country">
                    <TextField source="name"/>
                </ReferenceField>
                <NumberField source="trails_count"/>
                <ShowButton/>
                <EditButton/>
            </List>
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
)


export default withServerConstants(Region);
