import React from 'react';
import {useHistory} from 'react-router';
import {
    Create,
    SimpleForm,
    FileInput,
    FileField,
} from 'react-admin';

import Modal from 'components/Modal';
import CustomToolbar from '../components/CustomToolbar';

import './CreateTrailModal.scss';
import gpxParser from "gpxparser";
import {getBboxFromCoordinates, getPolylineFromCoordinates} from "../../utils/map";

const CreateTrailModal = ({isOpen, onCloseModal, modalTitle, serverConstants, ...rest}: any) => {
    const history = useHistory();

    const handleSubmit = (data: any) => {
        if (data.input_data) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                if (typeof reader.result === 'string') {
                    let gpx = new gpxParser();
                    gpx.parse(reader.result || '');
                    const route_data = gpx.tracks[0];
                    const route_coordinates = route_data.points.map(value => [value['lon'], value['lat']])
                    history.push({
                        pathname: '/trail/create',
                        state: {
                            name: route_data.name,
                            description: route_data.desc,
                            route_polyline: getPolylineFromCoordinates(route_coordinates),
                            bbox: getBboxFromCoordinates([route_coordinates]),
                            length: Math.round(route_data.distance.total),
                            longitude: route_coordinates[0][0],
                            latitude: route_coordinates[0][1],
                            source: 'imported',
                        },
                    });
                }
            });
            reader.readAsText(data.input_data.rawFile);
        } else {
            history.push({
                pathname: '/trail/create',
            });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            modalTitle={modalTitle}
        >
            <Create {...rest}>
                <SimpleForm
                    toolbar={CustomToolbar()}
                    save={handleSubmit}
                    onSubmit={() => {
                    }}
                >
                    <FileInput source="input_data" label="Upload route data(Optional)"
                               placeholder={<p>Drop a GPX to upload, or click to select it.</p>}>
                        <FileField source="input_data_src" title="title"/>
                    </FileInput>
                </SimpleForm>
            </Create>
        </Modal>
    );
};

export default CreateTrailModal;
