import React from "react";
import Map, {Layer, Marker, Source} from "react-map-gl";

import {getCoordinatesFromPolyline} from "../../utils/map";
import {LngLatBoundsLike, MercatorCoordinate, Popup, Marker as GlMarker} from "mapbox-gl";
import {LINE_ACTIVITY_COLOR, LINE_COLOR} from "../../constants/map";
import {If} from "../../typings/tsx-control-statements.d";
import * as turf from "@turf/turf";


const TrailPreviewMap = (props: any) => {
    const coordinates: any = getCoordinatesFromPolyline(
        props.record.route_polyline, props.record.route_polylines
    )
    const centerPoint = coordinates[0][(coordinates[0].length/2).toFixed()]
    const startPoint = coordinates[0][0];
    const finishPoint = coordinates[coordinates.length - 1][coordinates[coordinates.length - 1].length - 1];
    let bbox: LngLatBoundsLike | undefined = props.record?.bbox
    const initViewState = bbox ? {
        longitude: startPoint[0],
        latitude: finishPoint[1],
        zoom: 20,
        pitch: 0,
        bearing: 90,
        // interactive: false
    } : {
        longitude: -111.89110455,
        latitude: 41.0909022,
        zoom: 16,
        pitch: 80,
        bearing: 41,
    }

    const geojson: any = {
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: coordinates[0]
        }
    };
    const routeLayerStyle: any = {
        id: 'line-route',
        type: 'line',
        layout: {
            'line-join': 'round',
            'line-cap': 'round'
        },
        paint: {
            'line-color': LINE_COLOR,
            'line-width': 8,
        }
    };


    const onLoad = React.useCallback((e: any) => {
        const map = e.target;
        const animationDuration = 80000;
        const cameraAltitude = 3000;
        const targetRoute = coordinates[0];
        const cameraRoute = coordinates[0];
        // get the overall distance of each route so we can interpolate along them
        const routeDistance = turf.lineDistance(turf.lineString(targetRoute));
        const cameraRouteDistance = turf.lineDistance(
            turf.lineString(cameraRoute)
        );

        const popup = new Popup({ closeButton: false, className: 'Map__popup'});
        const marker = new GlMarker({
            color: 'red',
            scale: 0.8,
            draggable: false,
            pitchAlignment: 'auto',
            rotationAlignment: 'auto'
        })
            .setLngLat(startPoint)
            .setPopup(popup)
            .addTo(map)
            .togglePopup();
        let start: any;

        function frame(time: any) {
            if (!start) start = time;
            // phase determines how far through the animation we are
            const phase = (time - start) / animationDuration;
            // phase is normalized between 0 and 1
            // when the animation is finished, reset start to loop the animation
            if (phase > 1) {
                // wait 1.5 seconds before looping
                setTimeout(() => {
                    start = 0.0;
                }, 1500);
            }

            // use the phase to get a point that is the appropriate distance along the route
            // this approach syncs the camera and route positions ensuring they move
            // at roughly equal rates even if they don't contain the same number of points
            const alongRoute = turf.along(
                turf.lineString(targetRoute),
                routeDistance * phase
            ).geometry.coordinates;

            const alongCamera = turf.along(
                turf.lineString(cameraRoute),
                cameraRouteDistance * phase
            ).geometry.coordinates;

            // Pin
            const lngLat = {
                lng: alongCamera[0],
                lat: alongCamera[1]
            };
            const elevation = Math.floor(
                // Do not use terrain exaggeration to get actual meter values
                map.queryTerrainElevation(lngLat, { exaggerated: false })
            );
            // console.log(lngLat)
            popup.setHTML('Altitude: ' + elevation + 'm<br/>');
            marker.setLngLat(lngLat);

            // Flyover
            const camera = map.getFreeCameraOptions();
            // set the position and altitude of the camera
            camera.position = MercatorCoordinate.fromLngLat(
                {
                    lng: alongCamera[0],
                    lat: alongCamera[1]
                },
                cameraAltitude
            );
            // tell the camera to look at a point along the route
            camera.lookAtPoint({
                lng: alongRoute[0],
                lat: alongRoute[1]
            });

            map.setFreeCameraOptions(camera);
            window.requestAnimationFrame(frame);
        }

        window.requestAnimationFrame(frame);
        //
        // function rotateCamera(timestamp = 0) {
        //     map.rotateTo((timestamp / 100) % 360, { duration: 0 });
        //     window.requestAnimationFrame(rotateCamera);
        // }
        // rotateCamera(0)
    }, []);

    return (
        <Map
            id="PreviewMap"
            initialViewState={initViewState}
            style={{width: "100%", height: 800}}
            mapStyle="mapbox://styles/trailswelltech/cl7oj7gw0001u15ryxtvvshp2"
            terrain={{ 'source': 'mapbox-dem', 'exaggeration': 1.5 }}
            onLoad={onLoad}
        >
            <Source id="my-data" type="geojson" data={geojson}>
                <Layer {...routeLayerStyle} />
            </Source>
            <Source id="mapbox-dem" type="raster-dem" url="mapbox://mapbox.terrain-rgb"/>
            <If condition={startPoint[0] === finishPoint[0] && startPoint[1] === finishPoint[1]}>
                <Marker longitude={startPoint[0]} latitude={startPoint[1]} rotationAlignment="auto" pitchAlignment="auto" scale={0.8}>
                    <div className="Map__marker__start_finish" ></div>
                </Marker>
            </If>
            <If condition={startPoint[0] !== finishPoint[0] || startPoint[1] !== finishPoint[1]}>
                <Marker longitude={startPoint[0]} latitude={startPoint[1]}>
                    <div className="Map__marker__start" ></div>
                </Marker>
                <Marker longitude={finishPoint[0]} latitude={finishPoint[1]}>
                    <div className="Map__marker__finish" ></div>
                </Marker>
            </If>
        </Map>
    )
};

export default TrailPreviewMap;
