import React, { memo } from 'react';
import {
  Edit,
} from 'react-admin';

import RegionForm from 'forms/Region/RegionForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import RegionNormalizer from "../../normalizer/RegionNormalizer";

const RegionEdit = (props: ControllerPropsInterface) => {
  return (
    <Edit {...props}>
      <RegionForm
        actionType={ActionType.EDIT}
        onSuccess={defaultOnSuccess}
        redirect="list"
        transform={RegionNormalizer.update}
        {...props}
      />
    </Edit>
  )
};

export default memo(RegionEdit);
