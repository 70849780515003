import React from 'react';
import {
    TextField,
    Show,
    TabbedShowLayout,
    RichTextField,
    Tab,
    NumberField,
    BooleanField, FunctionField
} from 'react-admin';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, If, Otherwise, When} from 'typings/tsx-control-statements.d';
import { withServerConstants } from "../../providers/ServerConstantsProvider";
import {Record} from "ra-core";
import {getItemById} from "../../utils/common";
import TrailsShowList from "../../components/List/TrailsShowList";
import ExternalTrailMap from "../../components/Map/ExternalTrailMap";
import {isAdmin} from "../../utils/authProviderProvider";

const ExternalTrailShow = (props: ControllerPropsInterface) => (
    <Choose>
        <When condition={props.permissions}>
            <If condition={isAdmin(props.permissions)}>
                <Show {...props}>
                    <TabbedShowLayout>
                        <Tab label="general">
                            <TextField source="name"/>
                            <TextField source="city_id"/>
                            <FunctionField
                                source="state_id"
                                render={(record: Record | undefined) => getItemById(props.serverConstants?.statesList, record?.state_id)?.name}
                            />
                            <FunctionField
                                source="country_id"
                                render={(record: Record | undefined) => getItemById(props.serverConstants?.countriesList, record?.country_id)?.name}
                            />
                            <TextField source="park_id" />
                            <RichTextField source="overview" emptyText={'N/A'}/>
                            <RichTextField source="description"/>
                            <BooleanField source="is_closed" />
                            <BooleanField source="is_private_property" />
                            <TrailsShowList />
                        </Tab>
                        <Tab label="stats">
                            <NumberField source="latitude" />
                            <NumberField source="longitude" />
                            <NumberField source="popularity" />
                            <NumberField source="length" />
                            <NumberField source="elevation_gain" />
                            <TextField source="features" />
                            <TextField source="activities" />
                            <NumberField source="difficulty_rating" />
                            <NumberField source="duration_minutes" />
                            <NumberField source="duration_minutes_hiking" />
                            <NumberField source="duration_minutes_trail_running" />
                            <NumberField source="duration_minutes_mountain_biking" />
                            <NumberField source="duration_minutes_cycling" />
                            <TextField source="route_type" />
                            <NumberField source="visitor_usage" />
                            <NumberField source="avg_rating" />
                            <NumberField source="num_reviews" />
                            <NumberField source="num_text_reviews" />
                        </Tab>
                        <Tab label="map">
                            <ExternalTrailMap/>
                        </Tab>
                    </TabbedShowLayout>
                </Show>
            </If>
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
)


export default withServerConstants(ExternalTrailShow);
