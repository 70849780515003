import {makeStyles} from '@material-ui/core/styles';

export const userHasPermission = (permissions, allowedRoles) => {
    let isHasAccess = false;

    allowedRoles.map((item) => {
        if (permissions.includes(item)) {
            isHasAccess = true;
        }

        return false;
    });

    return isHasAccess;
};

export const getItemById = (list, id) => list.find((item) => item.id === id);

export const getChoicesList = (list) => {
    const choicesList = [];

    list.map(({key, title}) => choicesList.push({id: key, name: title}));

    return choicesList;
};

export const getProductTrialChoices = () => {
    const trialList = [];

    for (let i = 0; i <= 365; i++) {
        trialList.push({
            id: i,
            name: i !== 0 ? `${i.toString()} ${i !== 1 ? 'days' : 'day'}` : 'No Trial',
        });
    }

    return trialList;
};

export const getBranchDefaultRowStyle = (serverConstants, branch_id) => {
    const branch = getItemById(serverConstants.branchesList, branch_id);
    const project = getItemById(serverConstants.projectsList, branch.project_id);

    return {
        borderLeftColor: project.color,
        borderLeftWidth: 10,
        borderLeftStyle: 'solid',
    };
};

export const getDefaultRowStyle = (serverConstants, project_id) => {
    const project = getItemById(serverConstants.projectsList, project_id);

    return {
        borderLeftColor: project.color,
        borderLeftWidth: 10,
        borderLeftStyle: 'solid',
    };
};

export const getDefaultDataGripStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'transparent',
        borderLeftWidth: 10,
        borderLeftStyle: 'solid',
    },
});

export const toTitleCase = (str) => str.replace('_', ' ').replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
);

export const toFloat = (value) => {
    if ((value || value === 0)) {
        return (value / 100).toString().split('.')[1]?.length > 2
            ? (value / 100).toFixed(2)
            : (value / 100);
    }

    return null;
}

export const toInt = (value) => value ? Math.trunc(value * 100) : null;

export const getTimeChoices = (formData, currentTimeId) => {
    const usedValues = formData?.schedule.map((item) => item?.time);
    const list = [];

    const makeTime = (hour, minute) => {
        const normalizedHour = hour < 10 ? `0${hour}` : hour;
        const normalizedMinute = minute < 10 ? `0${minute}` : minute;

        return `${normalizedHour}:${normalizedMinute}`
    }
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60; j += 15) {
            const timeName = makeTime(i, j);
            const timeId = `${timeName}:00`;

            if (!usedValues.includes(timeId) || currentTimeId === timeId) {
                list.push({
                    id: timeId,
                    name: timeName,
                });
            }
        }
    }

    return list;
};

export const singleRouteFormatter = (value) => {
    if (value) {
        return 'Line'
    }
    return 'Multiline';
};


export const toHoursAndMinutes = (totalSeconds) => {
    const time = new Date(1970, 0, 1)
    time.setSeconds(totalSeconds)
    return `${String(time.getHours()).padStart(2, '0')}:${String(time.getMinutes()).padStart(2, '0')}:${String(time.getSeconds()).padStart(2, '0')}`;
}

export const cleanObject = (object) => {
    for (let propName in object) {
        if (object[propName] === null || object[propName] === undefined) {
            delete object[propName];
        }
    }

    return object;
};

export const toKm = (meters) => Math.round(meters) / 1000;
