import {SimpleFormProps} from "react-admin";


export enum ActionType {
    ADD = "add",
    EDIT = "edit"
}

export type DateTimeInterface = string;

export type EmailInterface = string;


export interface OpenModalInterface{
    openModal: () => void,
}

export interface ServerConstantsInfoInterface {
    language: {items: any},
    countriesList: {items: any},
    statesList: {items: any},
    activity_type: {items: any},
    difficulty: {items: any},
    trail_status: {items: any},
    trail_source: {items: any},
    route_type: {items: any},
    units: {items: any},
    gender: {items: any},
    map_type: {items: any},
    park_type: {items: any},
}
// TODO: add types
export interface ServerConstants extends ServerConstantsInfoInterface {
}

export interface FormPropsInterface extends SimpleFormProps{
    actionType: ActionType,
    transform: (values: any) => object,
    onSuccess: (props: any, redirect:any) => void,
}
export interface LazyFormPropsInterface extends FormPropsInterface{
    isLoaded: boolean
}
