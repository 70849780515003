import { PaletteType } from '@material-ui/core';

export const darkTheme = {
    palette: {
        primary: {
            main: '#ff2a60',
        },
        secondary: {
            main: '#ffbb50',
        },
        type: 'dark' as PaletteType,
    },
    sidebar: {
        width: 250,
    },
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                color: '#fff',
                backgroundColor: '#ff2a60',
            },
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit',
                },
            },
        },
        MuiToolbar: {
            root: {
                padding: '0 !important',
                alignItems: 'center !important',
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
    },
};

export const lightTheme = {
    palette: {
        primary: {
            main: '#ff2a60',
        },
        secondary: {
            light: '#ff2a60',
            main: '#000',
            dark: '#000',
            contrastText: '#fff',
        },
        background: {
            default: '#fcfcfe',
        },
        type: 'light' as PaletteType,
    },
    sidebar: {
        width: 250,
    },
    overrides: {
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid #fff',
            },
            active: {
                borderLeft: '3px solid #4f3cc9',
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                border: '1px solid #e0e0e3',
                backgroundClip: 'padding-box',
            },
        },
        MuiButton: {
            contained: {
                backgroundColor: '#fff',
                color: '#ff2a60',
                boxShadow: 'none',
            },
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit',
                },
            },
        },
        MuiAppBar: {
            colorSecondary: {
                color: '#ffffff',
                backgroundColor: '#ff2a60',
            },
            root: {
                border: 'none',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiSnackbarContent: {
            root: {
                border: 'none',
            },
        },
        MuiToolbar: {
            root: {
                padding: '0 !important',
                alignItems: 'center !important',
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
    },
};
