import AbstractNormalizer from './AbstractNormalizer';

class CountryNormalizer extends AbstractNormalizer {
    create(payload: any) {
        const data = payload;
        return data;
    }

    update(payload: any) {
        const data = super.beforeUpdate(payload);
        delete data['trails'];
        delete data['latitude_bottom_right'];
        delete data['latitude_top_left'];
        delete data['longitude_bottom_right'];
        delete data['longitude_top_left'];
        return data;
    }
}

export default new CountryNormalizer();
