import React, {memo} from 'react';
import { Create } from 'react-admin';

import ParkForm from 'forms/Park/ParkForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import ParkNormalizer from "../../normalizer/CityNormalizer";

const ParkAdd = (props: ControllerPropsInterface) => (
  <Create {...props}>
    <ParkForm
      actionType={ActionType.ADD}
      onSuccess={defaultOnSuccess}
      transform={ParkNormalizer.create}
      {...props}
    />
  </Create>
);

export default memo(ParkAdd);
