import React, { memo } from 'react';
import {
  Edit,
} from 'react-admin';

import CityForm from 'forms/City/CityForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import CityNormalizer from "../../normalizer/CityNormalizer";

const CityEdit = (props: ControllerPropsInterface) => {
  return (
    <Edit {...props}>
      <CityForm
        actionType={ActionType.EDIT}
        onSuccess={defaultOnSuccess}
        redirect="list"
        transform={CityNormalizer.update}
        {...props}
      />
    </Edit>
  )
};

export default memo(CityEdit);
