import React, {memo} from 'react';
import { Create } from 'react-admin';

import RegionForm from 'forms/Region/RegionForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import StateNormalizer from "../../normalizer/RegionNormalizer";

const StateAdd = (props: ControllerPropsInterface) => (
  <Create {...props}>
    <RegionForm
      actionType={ActionType.ADD}
      onSuccess={defaultOnSuccess}
      transform={StateNormalizer.create}
      {...props}
    />
  </Create>
);

export default memo(StateAdd);
