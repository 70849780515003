import React, {ComponentType} from 'react';
import {LayoutProps, Layout, ReduxState} from 'react-admin';
import { useSelector } from 'react-redux';

import CustomAppBar from 'components/AppBar';
import { lightTheme, darkTheme } from './themes';
import Menu from 'components/Menu';

const MainLayout = (props: ComponentType<LayoutProps>) => {
  const theme = useSelector((state: ReduxState) => state.theme === 'light' ? lightTheme : darkTheme);

  return (
    <Layout
      {...props}
      menu={Menu}
      appBar={CustomAppBar}
      theme={theme}
    />
  );
};

export default MainLayout;
