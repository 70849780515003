import * as React from "react";
import {Chip} from '@material-ui/core';

const PARK_TYPE_COLORS: any = {
    national_forest: "rgba(186,213,239,0.6)",
    national_park: "#cbefbe",
    state_park: "#eab3b3",
    mount_park: "rgb(253,88,88)",
    state_forest: "#dbd5f5",
    wilderness: "#beceee",
    regional_park: "#ffd6d6",
    reservation: "rgba(238,238,238,0.72)",
    reserve: "#e582d8",
    conservation_area: "#82cee5",
    recreational_area: "#a383ff",
    country_park: "#f56ecd",
    metro_park: "rgba(188,250,147,0.5)",
    nature_area: "#f5eb9b",
    wildlife_area: "#face87",
    management_area: "#f39bf8",
    local_park: "#dffc76",
    nature_preserve: "#52f6ea",
    nature_park:  "#ffffff"
}

export const ParkTypeChip = (props: any) => {
    const parkType = props.record[props.source]
    return (
        <Chip
            label={props.serverConstants?.park_type?.items[parkType]?.title}
            style={{backgroundColor: PARK_TYPE_COLORS[parkType], color: '#000'}}
        />
    )
}
