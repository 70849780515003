import React, {useState, useRef} from "react";
import {IconButton, Typography} from "@material-ui/core";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {makeStyles} from "@material-ui/core/styles";
import {Upload, TrashCan} from 'mdi-material-ui';
import {Field, useField} from 'react-final-form';

const useStyles = makeStyles(theme => ({
    imageInput: {
        maxWidth: '640px',
    },
    imageContainer: {
        position: 'relative',
        display: 'block',
        minHeight: '70px',
        maxWidth: '640px',
        marginLeft: 'auto',
        marginRight: 'auto',
        flexGrow: 0,
        marginBottom: "10px"
    },
    imageContainerSquare: {
        position: 'relative',
        display: 'block',
        minHeight: '70px',
        maxWidth: '240px',
        marginRight: 'auto',
        flexGrow: 0,
        marginBottom: "10px"
    },
    imageContainerImage: {
        maxWidth: '640px',
        display: 'block',
        backgroundColor: '#303030',
    },
    imageContainerImageSquare: {
        maxWidth: '240px',
        display: 'block',
        backgroundColor: '#303030',
    },
    imageContainerButton: {
        position: 'absolute',
        bottom: 0,
        right: 0
    },
    imageContainerClearButton: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    hidden: {
        display: 'none'
    }
}));

/**
 * This component renders the current image of a record, if it has one. On top of this, it
 * renders a small upload button at the bottom right corner of the image in order to change
 * the record's image. For this, the new image gets loaded with the ReactCrop component.
 *
 * @param record is the record from the datagrid.
 * @param source is the source name for the image.
 * @param props are the remaining parent-level properties,
 */
export const EditableImage = ({record, source, aspect, ...props}: any,) => {
    const classes = useStyles();
    const {input: {onChange}} = useField(source);

    // Contains the reference to the original image (objectURL)
    const imageRef = useRef<HTMLImageElement | null>(null);
    const initState = {
        // Contains the new (non-cropped) image
        src: null,
        // Contains the reference to the cropped image (objectURL)
        croppedImageUrl: undefined,
        // Contains the actual cropped image blob
        blob: undefined,
        // Settings for the ReactCrop component. We want square images, so we set the aspect ratio to 1
        crop: {
            unit: '%',
            height: 100,
            aspect: aspect || 1
        }
    }
    const [state, setState] = useState<any>(initState);

    /**
     * Gets called when a file gets selected in the upload dialog. Sets the "src" property on the state object.
     * @param e
     */
    const onSelectFile = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setState((prevState: any) => ({
                    ...prevState,
                    src: reader.result
                }))
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onClear = (record: any, source: any) => {
        setState(initState);
        delete record[source]
    };

    /**
     * Gets called when the image has been loaded
     */
    const onImageLoaded = (image: any) => {
        imageRef.current = image;
    };

    /**
     * Gets called each time the crop's changes are done (crop window released)
     */
    const onCropComplete = (crop: any) => {
        makeClientCrop(imageRef.current, crop);
    };

    /**
     * Gets called each time the crop is changing (size, location, ...)
     */
    const onCropChange = (crop: any) => {
        setState((prevState: any) => ({
            ...prevState,
            crop: crop
        }));
    };

    /**
     * Generates the cropped preview image
     */
    const makeClientCrop = async (imageRef: any, crop: any) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef,
                crop
            );
            setState((prevState: any) => ({
                ...prevState,
                croppedImageUrl: croppedImageUrl
            }));
        }
    };

    /**
     * Transforms the original image to a cropped copy by applying the cropping parameters
     */
    const getCroppedImg = (image: any, crop: any) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = Math.ceil(crop.width * scaleX);
        canvas.height = Math.ceil(crop.height * scaleY);
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width * scaleX,
                crop.height * scaleY,
            );
        }
        // As Base64 string
        const base64Image = canvas.toDataURL('image/jpeg');

        // Return promise with the new (cropped) image. For this, a new object URL gets created.
        // Furthermore, the image blob gets stored in the state object.
        return new Promise((resolve, reject) => {
            setState((prevState: any) => ({
                ...prevState,
                blob: base64Image,
            }));

        });
    };

    return (
        <div>
            <Field name={source} component="input" onChange={onChange(state.blob)} className={classes.hidden}/>
            <div className={state.crop.aspect === 1 ? classes.imageContainerSquare : classes.imageContainer}>
                {/*Image preview*/}
                <ImagePreview
                    id={source}
                    record={record}
                    state={state}
                    source={source}
                    aspect={state.crop.aspect}
                    onClear={(e: any) => onClear(record, source)}
                />

                <input accept="image/*" className={classes.hidden} id={"upload" + source} type="file"
                       onChange={onSelectFile}/>

                {/*Material design upload button*/}
                <label htmlFor={"upload" + source}>
                    <IconButton color="primary" component="span" className={classes.imageContainerButton}>
                        <Upload/>
                    </IconButton>
                </label>
            </div>
            <div className={classes.imageInput}>
                {/*
            We need this react-final-form field because it handles the react-admin update process
            Each time the cropped image changes, the onChange() method gets called with the current image
            */}
                {/*When the image is selected and loaded, the ReactCrop component can be shown*/}
                {state.src && (
                    <ReactCrop
                        src={state.src}
                        crop={state.crop}
                        ruleOfThirds
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                    />
                )}
            </div>
        </div>
    );
};

EditableImage.defaultProps = {addLabel: true};


const ImagePreview = (props: any) => {
    const classes = useStyles();

    let src = props.state.blob || props.record[props.source] || props.record[props.source.replace('_data','')];

    if (src) {
        return <>
            <img src={src || props.record.preview} alt="Preview"
                 className={props.aspect === 1 ? classes.imageContainerImageSquare : classes.imageContainerImage}/>
            <IconButton color="primary" component="span" className={classes.imageContainerClearButton}
                        onClick={props.onClear}>
                <TrashCan/>
            </IconButton>
        </>
    }

    return <Typography>No image</Typography>;
};
