import { Auth } from 'aws-amplify';
import { toTitleCase } from 'utils/common';
import {AuthProvider} from "react-admin";

const authProvider: AuthProvider = {
  login: ({username, password, clientMetadata}) => Auth.signIn(username, password, clientMetadata),
  checkError: () => Promise.resolve(),
  checkAuth: () => Auth.currentAuthenticatedUser().then((user) => {
    return Promise.resolve();
  }).catch(() => {
    Auth.federatedSignIn();
  }),
  logout: () => Auth.signOut(),
  getPermissions: () => Auth.currentSession().then((session) => {
    const groups = session.getAccessToken().decodePayload()['cognito:groups'];
    return groups ? Promise.resolve(groups) : Promise.reject();
  }).catch(() => {
    return Promise.reject();
  }),
  getIdentity: () => Auth.currentSession().then((session) => {
    const userFullName = session.getIdToken().decodePayload().email.split('@')[0].split('.');
    return Promise.resolve({
      id: `${toTitleCase(userFullName[0])} ${toTitleCase(userFullName[1])}`,
      fullName: `${toTitleCase(userFullName[0])} ${toTitleCase(userFullName[1])}`,
      avatar: '',
    });
  }).catch(() => {
    return Promise.reject();
  }),
}

export default authProvider;
