import { API } from 'aws-amplify';
import { getResponseWithErrors } from "utils/dataProvider";
import { API_NAME, DEFAULT_MAX_VALUE } from "constants/dataProvider";

const dataProvider = {
    getList: async (resource: string, { pagination, filter, sort }: any) => {
        if (filter.hasOwnProperty("q")) {
            filter["name"] = filter["q"]
            delete filter["q"]
        }
        const { items, _meta } = await API.get(
            API_NAME,
            `/${API_NAME}/${resource}`, {
            queryStringParameters: {
                page: pagination.page,
                per_page: pagination.perPage || DEFAULT_MAX_VALUE,
                ...filter,
                ...sort,
            }
        }
        );

        return { data: items, total: _meta.total_items };
    },
    getMany: async (resource: string, props: any) => {
        const { items, _meta } = await API.get(
            API_NAME,
            `/${API_NAME}/${resource}`, {
                queryStringParameters: {
                    page: 1,
                    per_page: 9999,
                    ids: props.ids.join(',')
                }
            }
        );
        return { data: items, total: _meta.total_items };
    },
    getOne: async (resource: string, { id }: any) => getResponseWithErrors(
        API.get(
            API_NAME,
            `/${API_NAME}/${resource}/${id}`,
            null
        )
    ),
    create: async (resource: string, params: any) => getResponseWithErrors(
        API.post(
            API_NAME,
            `/${API_NAME}/${resource}`,
            { body: params.data }
        )
    ),
    update: async (resource: string, params: any) => getResponseWithErrors(
        API.put(
            API_NAME,
            `/${API_NAME}/${resource}/${params.id}`,
            { body: params.data }
        ),
    ),
    delete: async (resource: string, params: any) => getResponseWithErrors(
        API.del(
            API_NAME,
            `/${API_NAME}/${resource}/${params.id}`,
            null
        ),
    ),
};

export default dataProvider;
