import {
    TextInput,
    required, NumberInput,
} from "react-admin";
import {withServerConstants} from "providers/ServerConstantsProvider";
import React from "react";
import SimpleFormCustom from "../SimpleFormCustom";
import {ActionType} from "../../interfaces/CommonInterface";
import {EditableImage} from "../../components/Image/EditableImageField";


const CountryForm = (props: any) => {
    return (
        <SimpleFormCustom
            isLoaded={!!props.record}
            {...props}
        >
            <TextInput source="name" validate={[required('Name is required')]}/>
            <TextInput
                multiline
                rows={5}
                source="description"
                fullWidth
                validate={[required('Description is required')]}
            />
            <EditableImage source="preview_img" {...props} label="Image" aspect={1.74}/>
            <TextInput source="slug" validate={[required('Slug is required')]}/>
            <TextInput source="iso_code" validate={[required('Iso Code is required')]}/>
            <NumberInput source="latitude" validate={[required('Latitude is required')]}/>
            <NumberInput source="longitude" validate={[required('Longitude is required')]}/>
            <NumberInput source="popularity"/>
        </SimpleFormCustom>
    )
};

export default withServerConstants(CountryForm)
