import React from 'react';
import { CreateButton } from 'react-admin';
import { OpenModalInterface } from 'interfaces/CommonInterface';

const CreateWithModalAction = ({ openModal }: OpenModalInterface) => (
  <div>
    <CreateButton
      onClick={(e) => {
        e.preventDefault();
          openModal();
      }}
    />
  </div>
);

export default CreateWithModalAction;
