import AbstractNormalizer from './AbstractNormalizer';

class UserNormalizer extends AbstractNormalizer {
    create(payload: any) {
        const data = payload;
        return data;
    }

    update(payload: any) {
        const data = super.beforeUpdate(payload, true);
        delete data.total_elevation_gain;
        delete data.total_calories_burned;
        delete data.total_distance;
        delete data.product_list;
        delete data.language;
        delete data.country;
        delete data.email_consent;
        delete data.last_session_at;
        delete data.total_activities;
        delete data.collections_order;
        delete data.last_completed_activity_at;
        delete data.reteno_contact_id;
        delete data.reasons;
        delete data.timezone;
        delete data.locale;
        delete data.total_collections;

        return data;
    }
}

export default new UserNormalizer();
