import React, { memo } from 'react';
import {
  Edit,
} from 'react-admin';

import ParkForm from 'forms/Park/ParkForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import ParkNormalizer from "../../normalizer/ParkNormalizer";

const ParkEdit = (props: ControllerPropsInterface) => {
  return (
    <Edit {...props}>
      <ParkForm
        actionType={ActionType.EDIT}
        onSuccess={defaultOnSuccess}
        redirect="list"
        transform={ParkNormalizer.update}
        {...props}
      />
    </Edit>
  )
};

export default memo(ParkEdit);
