import React from 'react';
import { Filter, TextInput } from 'react-admin';

const CountryFilter = (props: any) => {
  const { serverConstants, ...rest } = props;

  return (
    <Filter {...rest}>
        <TextInput source="id" alwaysOn resettable />
        <TextInput source="name" alwaysOn resettable />
    </Filter >
  );
};

export default CountryFilter;
