import { AnyAction } from 'redux'

const themeReducer = (previousState: string  = 'dark', action: AnyAction) => {
    if (action.type === 'CHANGE_THEME') {
        return action.payload;
    }
    return previousState;
};

export default themeReducer;
