import React from 'react';
import {
    FunctionField, ImageField,
    ReferenceField,
    RichTextField,
    Show,
    Tab,
    TabbedShowLayout, TextField
} from 'react-admin';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, Otherwise, When} from 'typings/tsx-control-statements.d';
import { withServerConstants } from "../../providers/ServerConstantsProvider";
import PointsMap from "../../components/Map/PointsMap";
import TrailMap from "../../components/Map/TrailMap";

const ParkShow = (props: ControllerPropsInterface) => (
    <Choose>
        <When condition={true}>
            <Show {...props}>
                <TabbedShowLayout>
                    <Tab label="general">
                        <TextField source="name"/>
                        <ReferenceField source="country_id" reference="country">
                            <TextField source="name"/>
                        </ReferenceField>
                        <ReferenceField source="state_id" reference="state">
                            <TextField source="name"/>
                        </ReferenceField>
                        <RichTextField source="description" emptyText={'N/A'}/>
                        <RichTextField source="moderator_notes" emptyText={'N/A'}/>
                        <ImageField source="thumb_img" emptyText="N/A"/>
                        <ImageField source="origin_img" emptyText="N/A"/>
                    </Tab>
                    <Tab label="map">
                        <PointsMap {...props}/>
                    </Tab>
                </TabbedShowLayout>
            </Show>
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
)


export default withServerConstants(ParkShow);
