import * as React from "react";
import {Chip} from '@material-ui/core';

const STATUS_COLORS: any = {
    done: "#d9ead3",
    draft: "#d3daea",
    wrong: "#e6b8af",
    no_stock_image: "#e2d9a2",
}

export const StatusChip = (props: any) => {
    const status = props.record[props.source]
    return (
        <Chip
            label={props.serverConstants?.trail_status?.items[status]?.title}
            style={{backgroundColor: STATUS_COLORS[status], color: '#000'}}
        />
    )
}
