import React from 'react';
import {
  List as ReactAdminList,
  Datagrid,
  ListProps,
  DatagridProps
} from 'react-admin';

import Pagination from 'components/Pagination';
import { getDefaultDataGripStyles } from "../../utils/common";

const List = (props: ListProps & DatagridProps) => {
  const {
    children,
    rowStyle,
    rowClick,
    expand,
    ...rest
  } = props;
  const dataGridClasses = getDefaultDataGripStyles();

  return (
    <ReactAdminList
      perPage={20}
      pagination={props.pagination || <Pagination />}
      bulkActionButtons={false}
      empty={false}
      {...rest}
    >
      <Datagrid
        rowStyle={rowStyle}
        rowClick={rowClick}
        classes={dataGridClasses}
        expand={expand}
        {...rest}
      >
        {children}
      </Datagrid>
    </ReactAdminList>
  );
};

export default List;
