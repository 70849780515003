import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify, {Auth} from 'aws-amplify';
import config from "./config";


Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.REGION,
        userPoolId: config.COGNITO_USER_POOL_ID,
        userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
        oauth: {
            domain: config.COGNITO_DOMAIN,
            scope: ['email', 'openid'],
            redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN,
            redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT,
            responseType: 'code',
        },
    },
    API: {
        endpoints: [
            {
                name: 'admin',
                endpoint: config.API_URL,
                region: config.REGION,
                custom_header: async () => (
                    {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken()
                            .getJwtToken()}`,
                        'x-api-key': config.API_KEY,
                        version: 1,
                    }
                ),
            },
    ],
  },

});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
