import React, { useEffect, useRef } from "react";
import './Map.scss';
import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoidHJhaWxzd2VsbHRlY2giLCJhIjoiY2wxM3VqaDZlMGt0NjNkczBjcDM0OHJ4YiJ9.gYVaRPAtX-O7_zcobVjPMg';

const MapInstance = (props: any) => {
    const { bbox, longitude, latitude, sources, layers, events, markers } = props
    const mapContainerRef = useRef(null);
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current || '',
            style: 'mapbox://styles/trailswelltech/cl7oj7gw0001u15ryxtvvshp2',
            bounds: bbox,
            center: [longitude, latitude],
            zoom: 15,
        });
        map.on('load', () => {
            sources.forEach((source: any) => map.addSource(source.id, source.data))
            layers.forEach((layer: any) => map.addLayer(layer))
            events.forEach((event: any) => map.on(event.type, event.layers, event.handler))
            markers.forEach((marker: any) => {
                new mapboxgl.Marker(marker.element)
                    .setLngLat(marker.location)
                    .addTo(map);
            })

            map.setZoom(map.getZoom() - 0.3)
        });
    }, [props]);

    return <div ref={mapContainerRef} className='Map' />
};

export default MapInstance;
