import React from 'react';
import {Filter, FormDataConsumer, TextInput} from 'react-admin';
import {renderFilterReferenceInput} from "../FiltersUtils";

const UserFilter = (props: any) => {
  const { serverConstants, ...rest } = props;

  return (
    <Filter {...rest}>
        <TextInput source="id" alwaysOn resettable />
        <TextInput source="email" alwaysOn resettable />
        {renderFilterReferenceInput('Country', 'country_id', 'country')}
        <FormDataConsumer form={'filterForm'} alwaysOn source='state_id'>
            {({ formData }) => renderFilterReferenceInput(
                'Region',
                'state_id',
                'state',
                { country_id: props.filterValues.country_id },
                (searchText: any) => ({name: searchText}),
                !formData.country_id
            )}
        </FormDataConsumer>
    </Filter >
  );
};

export default UserFilter;
