import React from "react";
import MapInstance from "./MapInstance";
import mapboxgl, {LngLatBoundsLike} from "mapbox-gl";
import {
    getBboxFromCoordinates, getCoordinates,
    getGeoJsonFromPolyline, isStartFinish,
} from "../../utils/map";
import {Choose, Otherwise, When} from "../../typings/tsx-control-statements.d";
import Loader from "../Loader";
import {LINE_COLOR} from "../../constants/map";


const TrailMap = (props: any) => {
    const sources: object[] = [];
    const layers: object[] = [];
    const markers: { location: any, element: any, popup: any }[] = [];
    const route_polyline = props.record.route_polyline || props.record.routePolyline || undefined;
    const coordinates: any = getGeoJsonFromPolyline(route_polyline)

    let route_coordinates = coordinates['coordinates'][0][1] < 0
        ? coordinates['coordinates'].map((item: any) => [item[1], item[0]])
        : coordinates['coordinates']

    let bbox: LngLatBoundsLike | undefined = getBboxFromCoordinates([route_coordinates])

    const drawMarkers = (coordinates: number[][], isSecondary: boolean = false) => {
        if (coordinates !== undefined) {
            const start: number[] = coordinates[0];
            const finish: number[] = coordinates[coordinates.length - 1];
            if (isStartFinish(start, finish)) {
                const element = document.createElement('div');
                console.log('Map__marker__start_finish' + (isSecondary ? '__secondary': ''))
                element.className = 'Map__marker__start_finish' + (isSecondary ? '__secondary': '');

                markers.push({
                    location: start,
                    element: element,
                    popup: new mapboxgl.Popup({offset: 25, closeButton: false, closeOnClick: false}).setText(
                        'START/FINISH'
                    )
                })
            } else {
                const startEl = document.createElement('div');
                startEl.className = 'Map__marker__start'+ (isSecondary ? '__secondary': '');
                const finishEl = document.createElement('div');
                finishEl.className = 'Map__marker__finish' + (isSecondary ? '__secondary': '');

                markers.push({
                    location: start,
                    element: startEl,
                    popup: new mapboxgl.Popup({offset: 25, closeButton: false, closeOnClick: false}).setText(
                        'START'
                    )
                })
                markers.push({
                    location: finish,
                    element: finishEl,
                    popup: new mapboxgl.Popup({offset: 25, closeButton: false, closeOnClick: false}).setText(
                        'FINISH'
                    )
                })
            }
        }
    }

    const drawRoute = (coordinates: number[][], source_id: number, color: string, opacity: number = 1) => {
        sources.push({
            id: 'route-' + source_id,
            data: {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': coordinates,
                    }
                }
            }
        });

        layers.push({
            'id': 'route-' + source_id,
            'type': 'line',
            'source': 'route-' + source_id,
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-color': color,
                'line-width': 8,
                'line-opacity': opacity
            }
        });
    }

    if (props.record.nearby_trails.length > 0) {
        props.record.nearby_trails.forEach((nearby_trail: any) => {
            let nearby_coordinates = getCoordinates(nearby_trail.route_polyline)
            drawRoute(nearby_coordinates, nearby_trail.id, '#ff2a60', 0.4)
            drawMarkers(nearby_coordinates, true)
        })
    }
    drawMarkers(route_coordinates)
    drawRoute(route_coordinates, props.record.id, LINE_COLOR)


    return <Choose>
        <When condition={!!route_polyline || !!props.record.route_polylines}>
            <MapInstance
                bbox={bbox}
                longitude={props.record.longitude || route_coordinates[0][0]}
                latitude={props.record.latitude || route_coordinates[0][1]}
                sources={sources}
                layers={layers}
                events={[]}
                markers={markers}
            />
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
};

export default TrailMap;
