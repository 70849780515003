import React, {memo} from 'react';
import { Create } from 'react-admin';

import CityForm from 'forms/City/CityForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import CityNormalizer from "../../normalizer/CityNormalizer";

const CityAdd = (props: ControllerPropsInterface) => (
  <Create {...props}>
    <CityForm
      actionType={ActionType.ADD}
      onSuccess={defaultOnSuccess}
      transform={CityNormalizer.create}
      {...props}
    />
  </Create>
);

export default memo(CityAdd);
