import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import Loader from '../../components/Loader';

const ServerConstantsContext = React.createContext([]);

class serverConstantsProvider extends PureComponent {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      info: null,
      countriesList: null,
      statesList: null,
      isLoaded: false,
    };
  }

  async componentDidMount() {
    try {
      await Promise.all([
        this.loadingInfo(),
        this.loadingCountries(),
        this.loadingStates(),
      ])

      this.setState({
        isLoaded: true,
      });
    } catch (e) {
      this.setState({
        isLoaded: true,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadingInfo = async () => {
    const data = await API.get('admin', '/admin/info', null);

    if (this._isMounted) this.setState({ info: { ...data } });
  };

  loadingCountries = async () => {
    const init = {
      queryStringParameters: {
        page: 1,
        per_page: 9999,
      },
    };
    const { items: countriesList } = await API.get('admin', '/admin/country', init);
    if (this._isMounted) this.setState({ countriesList });
  };

  loadingStates = async () => {
    const init = {
      queryStringParameters: {
        page: 1,
        per_page: 9999,
      },
    };
    const { items: statesList } = await API.get('admin', '/admin/state', init);

    if (this._isMounted) this.setState({ statesList });
  };


  render() {
    const {
      info,
      countriesList,
      statesList
    } = this.state;
    const providerValue = {
      countriesList,
      statesList,
      ...info
    };
    console.log(providerValue)

    return (
      <ServerConstantsContext.Provider value={providerValue}>
        {
          this.state.isLoaded
            ? this.props.children
            : <div><Loader /></div>
        }
      </ServerConstantsContext.Provider>
    );
  }
}

export const ServerConstantsConsumer = ServerConstantsContext.Consumer;

serverConstantsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default serverConstantsProvider;
