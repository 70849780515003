import React from 'react';
import {
    TextField,
    Show,
    TabbedShowLayout,
    Tab,
    NumberField,
    BooleanField,
    ReferenceField,
    ImageField,
    EmailField,
    DateField
} from 'react-admin';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, If, Otherwise, When} from 'typings/tsx-control-statements.d';
import {withServerConstants} from "../../providers/ServerConstantsProvider";
import {isAdmin} from "../../utils/authProviderProvider";

const UserShow = (props: ControllerPropsInterface) => (
    <Choose>
        <When condition={props.permissions}>
            <If condition={isAdmin(props.permissions)}>
                <Show {...props}>
                    <TabbedShowLayout>
                        <Tab label="general">
                            <TextField source="id" />
                            <ImageField source="profile_image_url" emptyText={'N/A'}/>
                            <EmailField source="email" emptyText={'N/A'}/>
                            <TextField source="name" emptyText={'N/A'}/>
                            <TextField source="last_name" emptyText={'N/A'}/>
                            <TextField source="country" label={'Registration Country'}/>
                            <ReferenceField source="country_id" reference="country" emptyText={'N/A'}><TextField source="name" /></ReferenceField>
                            <ReferenceField source="state_id" reference="state" emptyText={'N/A'}><TextField source="name" /></ReferenceField>
                            <TextField source="language" emptyText={'N/A'}/>
                            <TextField source="default_activity_type" emptyText={'N/A'}/>
                            <TextField source="default_map_type" emptyText={'N/A'}/>
                            <TextField source="total_activities" emptyText={'0'}/>
                            <NumberField source="total_distance" emptyText={'0'}/>
                            <NumberField source="total_elevation_gain" emptyText={'0'}/>
                            <NumberField source="total_calories_burned" emptyText={'0'}/>
                            <TextField source="phone" emptyText={'N/A'}/>
                            <TextField source="gender" emptyText={'N/A'}/>
                            <NumberField source="age" emptyText={'N/A'}/>
                            <NumberField source="height" emptyText={'N/A'}/>
                            <NumberField source="weight" emptyText={'N/A'}/>
                            <TextField source="units" emptyText={'N/A'}/>
                            <BooleanField source="is_paid" emptyText={'N/A'}/>
                            <BooleanField source="is_registration_completed" emptyText={'N/A'}/>
                            <BooleanField source="email_consent" emptyText={'N/A'}/>
                            <NumberField source="sessions_count" emptyText={'0'}/>
                            <BooleanField source="is_nps_sent" emptyText={'N/A'}/>
                            <TextField source="product_list" emptyText={'N/A'}/>
                            <DateField source="created_at" showTime/>
                            <DateField source="updated_at" emptyText={'N/A'}/>
                        </Tab>
                    </TabbedShowLayout>
                </Show>
            </If>
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
)


export default withServerConstants(UserShow);
