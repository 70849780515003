import {
    TextInput,
    required, AutocompleteInput, ReferenceInput, NumberInput,
} from "react-admin";
import {withServerConstants} from "providers/ServerConstantsProvider";
import React from "react";
import SimpleFormCustom from "../SimpleFormCustom";
import {ActionType} from "../../interfaces/CommonInterface";
import {EditableImage} from "../../components/Image/EditableImageField";


const CountryForm = (props: any) => {
    return (
        <SimpleFormCustom
            isLoaded={props.actionType == ActionType.ADD || !!props.record}
            {...props}
        >
            <TextInput source="name" validate={[required('Name is required')]}/>
            <TextInput
                multiline
                rows={5}
                source="description"
                fullWidth
                validate={[required('Description is required')]}
            />
            <ReferenceInput label="Country" source="country_id" reference="country"
                            sort={{}}
                            validate={[required('Country is required')]}
            >
                <AutocompleteInput optionText="name" resettable={true}/>
            </ReferenceInput>
            <EditableImage source="preview_img" {...props} label="Image" aspect={1.74}/>
            <TextInput source="slug" validate={[required('Slug is required')]}/>
            <NumberInput source="latitude" validate={[required('Latitude is required')]}/>
            <NumberInput source="longitude" validate={[required('Longitude is required')]}/>
            <NumberInput source="popularity"/>
        </SimpleFormCustom>
    )
};

export default withServerConstants(CountryForm)
