import React from 'react';
import {
    EditButton,
    NumberField,
    ReferenceField,
    ShowButton,
    TextField,
} from 'react-admin';
import List from 'components/List';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, Otherwise, When} from 'typings/tsx-control-statements.d';
import { withServerConstants } from "../../providers/ServerConstantsProvider";
import CityFilter from "../../filters/Trail/CityFilter";
import {Record} from "ra-core";
import {getItemById} from "../../utils/common";

const rowStyle = (record: any) => ({
    borderLeftColor: '#333',
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
});


const City = (props: ControllerPropsInterface) => (
    <Choose>
        <When condition={true}>
            <List
                filters={<CityFilter />}
                filterDefaultValues={{ country_id: 205 }}
                rowStyle={(record) => rowStyle(record)}
                {...props}
            >
                <TextField source="name"/>
                <ReferenceField source="country_id" reference="country">
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source="state_id" reference="state">
                    <TextField source="name"/>
                </ReferenceField>
                <NumberField source="trails_count"/>
                <ShowButton />
                <EditButton/>
            </List>
        </When>
        <Otherwise>
            <Loader/>
        </Otherwise>
    </Choose>
)


export default withServerConstants(City);
