import React, {memo} from 'react';
import { Create } from 'react-admin';

// import ExperimentsNormalizer from 'normalizer/ExperimentsNormalizer';
import TrailForm from 'forms/Trail/TrailForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import TrailNormalizer from "../../normalizer/TrailNormalizer";

const TrailAdd = (props: ControllerPropsInterface) => (
  <Create {...props}>
    <TrailForm
      actionType={ActionType.ADD}
      onSuccess={defaultOnSuccess}
      transform={TrailNormalizer.create}
      {...props}
    />
  </Create>
);

export default memo(TrailAdd);
