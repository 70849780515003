import React, { Fragment } from 'react';
import { List, ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  icon: {minWidth: theme.spacing(5)},
  sidebarIsOpen: {
    '& a': {
      paddingLeft: theme.spacing(4),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  sidebarIsClosed: {
    '& a': {
      paddingLeft: theme.spacing(2),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
}));

const SubMenu = ({
   handleToggle,
   sidebarIsOpen,
   name,
   icon,
   children,
   dense,
} : any) => {
  const classes = useStyles();

  const header = (
      <MenuItem dense={dense} button onClick={handleToggle}>
        <ListItemIcon className={classes.icon}>
          {icon}
        </ListItemIcon>
        <Typography variant="inherit" color="textSecondary">
          {name}
        </Typography>
      </MenuItem>
  );

  return (
    <Fragment>
      {sidebarIsOpen ? (header) : (
          <Tooltip title={name} placement="right">
            {header}
          </Tooltip>
      )}
        <List
          dense={dense}
          component="div"
          disablePadding
          className={sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}
        >
          {children}
        </List>
    </Fragment>
  );
};

export default SubMenu;
