import React, {useState} from 'react';
import {
    EditButton,
    ReferenceField,
    ShowButton,
    TextField,
    FunctionField,
    ImageField,
    DateField,
    ExportButton,
    TopToolbar,
    useListContext,
    sanitizeListRestProps,
} from 'react-admin';
import List from 'components/List';
import {ControllerPropsInterface} from "interfaces/ControllerPropsInterface";
import Loader from "components/Loader";

import {Choose, If, Otherwise, When} from 'typings/tsx-control-statements.d';
import {withServerConstants} from "../../providers/ServerConstantsProvider";
import TrailFilter from "../../filters/Trail/TrailFilter";
import ChipList from "../../components/ChipList/ChipList";
import {StatusChip} from "../../components/Chip/StatusChip";
import {SourceChip} from "../../components/Chip/SourceChip";
import {isNotViewer} from "../../utils/authProviderProvider";
import CreateTrailModal from "../../modals/CreateTrailModal";
import CreateWithModalAction from "../../actions";

const rowStyle = (record: any) => ({
    borderLeftColor: '#333',
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
});


const ListActions = (props: any) => {
    const {
        className,
        maxResults,
        setIsOpen,
        filters,
        ...rest
    } = props;
    const { total } = useListContext();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <CreateWithModalAction openModal={() => setIsOpen(true)} />
            <ExportButton disabled={total === 0} maxResults={maxResults} />
        </TopToolbar>
    );
};

const Trail = (props: ControllerPropsInterface) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Choose>
            <When condition={props.permissions}>
                <List
                    filters={<TrailFilter serverConstants={props.serverConstants}/>}
                    filterDefaultValues={{ length_from: 1000,  country_id: 205}}
                    rowStyle={(record) => rowStyle(record)}
                    actions={<ListActions setIsOpen={setIsOpen} {...props}/>}
                    sort={{field: 'id',order: 'DESC'}}
                    {...props}
                >
                    <TextField source="id"/>
                    <ImageField source="thumb_img" emptyText="N/A"/>
                    <ImageField source="map_preview_thumb" emptyText="N/A"/>
                    <TextField source="name" label="Name"/>
                    <StatusChip source="status" serverConstants={props.serverConstants}/>
                    <SourceChip source="source" serverConstants={props.serverConstants}/>
                    <ReferenceField source="country_id" reference="country">
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField source="state_id" reference="state">
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField source="city_id" reference="city">
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField source="park_id" reference="park" emptyText={'N/A'}>
                        <TextField source="name" />
                    </ReferenceField>
                    <FunctionField source="route_type" render={
                        ({route_type}: any) => props.serverConstants?.route_type?.items[route_type]?.title || route_type}
                    />
                    <FunctionField source="difficulty" render={
                        ({difficulty}: any) => props.serverConstants?.difficulty?.items[difficulty]?.title || difficulty}
                    />
                    <TextField source="avg_rating" label="Rating"/>
                    <ChipList
                        source="activities"
                        sortable={false}
                        normalizer={(obj: any) => props.serverConstants?.activity_type?.items[obj]?.title || obj}
                    />
                    <TextField source="created_by"  emptyText={'N/A'}/>
                    <TextField source="last_updated_by" emptyText={'N/A'}/>
                    <DateField source="created_at" showTime/>
                    <DateField source="updated_at" showTime/>
                    <ShowButton/>
                    <EditButton/>
                </List>
                <If condition={isNotViewer(props.permissions)}>
                    <CreateTrailModal
                        modalTitle="Create Trail"
                        isOpen={isOpen}
                        onCloseModal={() => setIsOpen(false)}
                        {...props}
                    />
                </If>
            </When>
            <Otherwise>
                <Loader/>
            </Otherwise>
        </Choose>
    )
}


export default withServerConstants(Trail);
