import React, { memo } from 'react';
import {
  Edit,
} from 'react-admin';

import CountryForm from 'forms/Country/CountryForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import CountryNormalizer from "../../normalizer/CountryNormalizer";

const CountryEdit = (props: ControllerPropsInterface) => {
  return (
    <Edit {...props}>
      <CountryForm
        actionType={ActionType.EDIT}
        onSuccess={defaultOnSuccess}
        redirect="list"
        transform={CountryNormalizer.update}
        {...props}
      />
    </Edit>
  )
};

export default memo(CountryEdit);
