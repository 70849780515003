import * as React from "react";
import {Chip} from "@material-ui/core";
import {useRecordContext} from "react-admin";
import get from "lodash/get";


const ChipList = (props: any) => {
    const { source, normalizer } = props;
    const record = useRecordContext(props);
    const data = get(record, source);
    return (
        <>
            {data ? data.map(function (item: any) {
                return <Chip
                    label={normalizer ? normalizer(item) : item}
                    style={{margin: '0 5px 5px 0'}}
                />
            }) : 'N/A'}
        </>
    )
}
export default ChipList;
