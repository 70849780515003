import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const LinkToTrail = ({ record }: any) => (
    <Button
        color="primary"
        component={Link}
        to={{ pathname: `/trail/${record.trail_id}/show` }}
        target="_blank"
        startIcon={<OpenInNewIcon />}
    >
        Main Trail Link
    </Button>
);

export default LinkToTrail
